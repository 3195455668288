import React from 'react';
import NavbarFive from "./Common/NavbarFive";
import Preloader from "./Common/Preloader";
import Footer from "../components/Common/Footer";
import aboutimg from '../styles/img/appointment-bg4.jpg';
import soapimg from '../styles/img/soaps.webp';
import spanimg from '../styles/img/society-for-pediatric-anesthesias.png';
import naftimg from '../styles/img/naftnets.png';
import smfmimg from '../styles/img/ssmfm.png';
import ifmssimg from '../styles/img/Horiz-Color-Logo-IFMSSs.png';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from 'classnames';
import { isComingSoon } from "../utils/helper";
import ComingSoon from "./Common/ComingSoon";

const Affiliates = () => {
    const { affiliatesData, homeData } = useSelector((state) => state.pageContents);
    const [loader, setLoader] = React.useState(true);
    React.useEffect(() => {
        setTimeout(() => setLoader(false), 1500);
    }, []);
    const chunkSize = 3;

    return (
        <>
            {
                homeData?.comingsoon && isComingSoon(homeData.comingsoon, 'Affiliates') ?
                    <ComingSoon />
                    :
                    <>
                        <NavbarFive />

                        <div className="page-title-area" style={{ backgroundImage: `url('${affiliatesData?.affiliatesbanner?.banner_image}')` }}>
                            <div className="d-table">
                                <div className="d-table-cell">
                                    <div className="container">
                                        <div className="page-title-content">
                                            <h2>{affiliatesData?.affiliatesbanner?.banner_title}</h2>
                                            <ul>
                                                <li><a href="/">Home</a></li>
                                                <li className="active">{affiliatesData?.affiliatesbanner?.banner_title}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='affiliates-sec'>
                            <div className='affliaties-main affliaties-bg-new'>
                                <div className='container'>
                                    <div className='row'>
                                        <h2>Our Partners</h2>
                                    </div>
                                </div>
                            </div>
                            <div className='affiliates-inner affli-inner-sec'>
                                <div className='container'>
                                    {affiliatesData?.affiliates.map((item, index) => {
                                        if (index % chunkSize === 0) {
                                            const chunk = affiliatesData?.affiliates.slice(index, index + chunkSize);
                                            return (
                                                <div key={index} className={classNames("row", {
                                                    "affiliates-margin": index % 2 !== 0
                                                })}>
                                                    {
                                                        affiliatesData?.affiliates.length > 3 && chunk.length < 3 && chunk.length >= 1 ? <div className={classNames({
                                                            "col-md-2": chunk.length === 2,
                                                            "col-md-4": chunk.length === 1,
                                                        })}></div> : null
                                                    }
                                                    {chunk.map((chunkItem, chunkIndex) => (
                                                        <div className="col-md-4 affiliate-row" key={chunkIndex}>
                                                            <div className='affiliates-box'>
                                                                <div className='affiliates-img'>
                                                                    <img src={chunkItem.affiliates_image} alt='soap' />
                                                                </div>
                                                                <div className='affiliates-box-sec'>
                                                                    <Link to={chunkItem.link} target="_blank"><p>{chunkItem.affiliates_title}</p></Link>
                                                                    <Link to={chunkItem.link} target="_blank"><a>Click Here</a></Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}
                                </div>
                            </div>
                        </div>

                        <Footer />

                    </>
            }
            {loader ? <Preloader /> : null}
        </>
    )
}

export default Affiliates;
