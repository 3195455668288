
import React, { useState } from "react";

const ReadMore = ({ children }) => {
    const characters = 250;
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <p className="text">
            {isReadMore ? text.slice(0, characters) : text}
            {
                text && text.length > characters &&
                <span onClick={toggleReadMore} className="read-or-hide">
                    {isReadMore ? " ...read more" : " read less"}
                </span>
            }
        </p>
    );
}
export default ReadMore;
