import React from "react";
import NavbarFive from "../components/Common/NavbarFive";
import Preloader from "./Common/Preloader";
import Footer from "../components/Common/Footer";
import aboutimg from "../styles/img/appointment-bg4.jpg";
import involveimgone from "../styles/img/blog3.jpg";
import involveimgsec from "../styles/img/imgnew.jpg";
import involveimgsecthree from "../styles/img/involvethree.jpg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { isComingSoon } from "../utils/helper";
import ComingSoon from "./Common/ComingSoon";
const Getinvolved = () => {
  const { membershipData, homeData } = useSelector(
    (state) => state.pageContents
  );
  const [loader, setLoader] = React.useState(true);
  React.useEffect(() => {
    setTimeout(() => setLoader(false), 1500);

    const hash = window.location.hash;
    const sectionId = hash.substring(1);
    if (sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        scrollToSection(element);
      }
    }
  }, []);

  const scrollToSection = (elem) => {
    window.scrollTo({
      top: elem.offsetTop - 50,
      behavior: "smooth",
    });
  };

  return (
    <>
      {homeData?.comingsoon &&
      isComingSoon(homeData.comingsoon, "Get Involved") ? (
        <ComingSoon />
      ) : (
        <>
          <NavbarFive />

          <div
            className="page-title-area"
            style={{
              backgroundImage: `url('${membershipData?.getInvolvedbanner?.banner_image}')`,
            }}
          >
            <div className="d-table">
              <div className="d-table-cell">
                <div className="container">
                  <div className="page-title-content">
                    <h2>Membership</h2>
                    <ul>
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li className="active">
                        {membershipData?.getInvolvedbanner?.banner_title}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {(membershipData?.getInvolved || homeData?.involvedata.length) && (
            <div className="how-its-work involve-top">
              {membershipData?.getInvolved && (
                <div className="container">
                  <div className="section-title bene-title affl-ben-bottm">
                    {/* <span className="top-title">Woks</span> */}
                    <h2>
                      {membershipData.getInvolved.get_involved_section_title}
                    </h2>
                    <p>{membershipData.getInvolved.get_involved_des}</p>
                  </div>
                </div>
              )}
              {homeData?.involvedata.map((item, index) => (
                <div
                  className={classNames("involve-first", {
                    "involve-sec": index % 2 === 0,
                  })}
                  id={`getinvolved-${item.involve_id}`}
                  key={index}
                >
                  <div
                    className={classNames("container margin-bottoms margin-involo", {
                        "margin-add": index % 2 !== 0
                    })}
                    style={{ marginBottom: "40px",marginTop:"30px" }}
                  >
                    <div className="row align-items-center">
                      {index % 2 === 0 && (
                        <div className="col-lg-6">
                          <div className={classNames("about-img involve-img img-top-getinvol", {
                            "last-getinvol": index === homeData.involvedata.length - 1
                          })}>
                            <img src={item.involve_image} />
                          </div>
                        </div>
                      )}
                      <div className="col-lg-6 about-areass involvesecwhite two">
                        <div className={classNames("about-content", {
                            "getinvol-odd": index % 2 !== 0,
                            "getinvol-even": index % 2 === 0
                        })}>
                          <h2 className="about-mid-cont about-mid-conts">
                            {item.involve_title}
                          </h2>
                          <p>{item.involve_description}</p>
                          <strong>{item.involve_sub_title}</strong>
                          <ul>
                            <li>
                              <i className="flaticon-tick"></i>
                              {item.involve_first}
                            </li>
                            <li>
                              <i className="flaticon-tick"></i>
                              {item.involve_second}
                            </li>
                            <li>
                              <i className="flaticon-tick"></i>
                              {item.involve_third}
                            </li>
                            <li>
                              <i className="flaticon-tick"></i>
                              {item.involve_four}
                            </li>
                            <li>
                              <i className="flaticon-tick"></i>
                              {item.involve_five}
                            </li>
                            <li>
                              <i className="flaticon-tick"></i>
                              {item.involve_six}
                            </li>
                          </ul>
                          <Link to="/onlineapplication" className="default-btn">
                            Join Now
                          </Link>
                        </div>
                      </div>
                      {index % 2 !== 0 && (
                        <div className="col-lg-6">
                          <div className="about-img involve-img img-top-getinvol">
                            <img src={item.involve_image} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}

              {/*<div className='involve-first involve-sec' >
                        <div className="container margin-bottoms margin-involo" style={{ marginBottom: '100px' }}>
                            <div className="row align-items-center">
                                <div className="col-lg-6 about-areass involvesecwhite two">
                                    <div className="about-content">

                                        <h2 className="about-mid-cont about-mid-conts">Diagnosis test</h2>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                            do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                            Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                                            maecenas accumsan lacus vel facilisis.Lorem ipsum dolor sit amet.
                                        </p>
                                        <strong>
                                            What Can I Do To Prevent Myself & prevent Disease
                                        </strong>

                                        <ul>
                                            <li>
                                                <i className="flaticon-tick"></i>
                                                Aliquam tincidunt mauris eu risus.
                                            </li>
                                            <li>
                                                <i className="flaticon-tick"></i>
                                                Vestibulum auctor dapibus neque.
                                            </li>
                                            <li>
                                                <i className="flaticon-tick"></i>
                                                Nunc dignissim risus id metus
                                            </li>
                                            <li>
                                                <i className="flaticon-tick"></i>
                                                Integer vitae libero ac risus egestas placerat.
                                            </li>
                                            <li>
                                                <i className="flaticon-tick"></i>
                                                Vestibulum commodo felis quis tortor.
                                            </li>
                                            <li>
                                                <i className="flaticon-tick"></i>
                                                Donec quis dui at dolor tempor interdum.
                                            </li>

                                        </ul>
                                        <Link href="/" className="default-btn">
                                            Join Now
                                        </Link>

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="about-img involve-img">
                                        <img src={involveimgsec} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='involve-first' >
                        <div className="container margin-bottoms margin-involo" style={{ marginBottom: '100px' }}>
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="about-img involve-img">
                                        <img src={involveimgsecthree} />
                                    </div>
                                </div>
                                <div className="col-lg-6 about-areass involvesecwhite two">
                                    <div className="about-content">

                                        <h2 className="about-mid-cont about-mid-conts">Health care counseling </h2>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                            do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                            Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                                            maecenas accumsan lacus vel facilisis.Lorem ipsum dolor sit amet.
                                        </p>
                                        <strong>
                                            What Can I Do To Prevent Myself & prevent Disease
                                        </strong>

                                        <ul>
                                            <li>
                                                <i className="flaticon-tick"></i>
                                                Fusce pellentesque suscipit nibh.
                                            </li>
                                            <li>
                                                <i className="flaticon-tick"></i>
                                                Vestibulum commodo felis quis tortor.
                                            </li>
                                            <li>
                                                <i className="flaticon-tick"></i>
                                                Vivamus vestibulum ntulla nec ante.
                                            </li>
                                            <li>
                                                <i className="flaticon-tick"></i>
                                                Donec quis dui at dolor tempor interdum.
                                            </li>
                                            <li>
                                                <i className="flaticon-tick"></i>
                                                Cras iaculis ultricies nulla.
                                            </li>
                                            <li>
                                                <i className="flaticon-tick"></i>
                                                Vestibulum auctor dapibus neque.
                                            </li>

                                        </ul>
                                        <Link href="/" className="default-btn">
                                            Join Now
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>*/}
            </div>
          )}
          <Footer />
        </>
      )}
      {loader ? <Preloader /> : null}
    </>
  );
};
export default Getinvolved;
