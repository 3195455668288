import React, { useState } from "react";
import DatePicker from "react-datepicker";
import Navbar from "../components/Common/Navbar";
import PageBanner from "../components/Common/PageBanner";
// import Subscribe from "../components/Common/Subscribe";
import Footer from "../components/Common/Footer";
import NavbarFive from "./Common/NavbarFive";
import { Link } from "react-router-dom";
import doctor_img from '../styles/img/doctor4.jpg';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import signinimg from '../styles/img/sign-in-imgnew.png';
import Preloader from "./Common/Preloader";
import aboutimg from '../styles/img/appointment-bg4.jpg';
import twiiterimg from '../styles/img/twitter-logo_white.png';


import { useSelector } from "react-redux";

import Pagination from './Common/Pagination';
import {getPaginatedItems} from '../utils/helper';
import {getformupmeet,getformprevmeet} from '../actions/pagecontent.actions';
import { isComingSoon } from "../utils/helper";
import ComingSoon from "./Common/ComingSoon";

const DoctorDetails = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [loader, setLoader] = React.useState(true);
  const { meetingsData ,homeData} = useSelector((state) => state.pageContents);

  const allMeeting = meetingsData ? meetingsData.previewmeeting : [];
  const [currentPageData, setCurrentPageData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage =2;
  const totalItems = allMeeting.length;
  const handlePageChange = (pageNumber) => {
    setCurrentPageData(getPaginatedItems(allMeeting, pageNumber, itemsPerPage));
    setCurrentPage(pageNumber);
  }

  React.useEffect(() => {
    setCurrentPageData(getPaginatedItems(allMeeting, currentPage, itemsPerPage))
  }, [allMeeting]);

  React.useEffect(() => {
    setTimeout(() => setLoader(false), 1500);
  }, []);

  const handleDownloadFile = (id,docname) => {
    getformupmeet(id)
      .then(res => {
        downloadBlob(res.data, docname);
      })
  }
  const handleDownloadprevFile = (id,docname) => {
    getformprevmeet(id)
        .then(res => {
          downloadBlob(res.data, docname);
        })
    }


  const downloadBlob = (data, filename) => {
    // Create a blob from the res data
    const blob = new Blob([data], { type: data.type });

    // Create a link element
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();

    // Cleanup
    window.URL.revokeObjectURL(link.href);
    document.body.removeChild(link);

  }
  return (
    <>
     {
        homeData?.comingsoon && isComingSoon(homeData.comingsoon, 'Meeting') ?
          <ComingSoon />
          :
          <>
       <NavbarFive />
       {
    meetingsData?.meetingbanner &&
        <>

   <div className="page-title-area" style={{ backgroundImage:  `url('${meetingsData.meetingbanner.banner_image}')` }}>
          <div className="d-table">
              <div className="d-table-cell">
                <div className="container">
                  <div className="page-title-content">
                        <h2>{meetingsData.meetingbanner.banner_title}</h2>
                        <ul>
                          <li><a href="/">Home</a></li>
                          <li className="active">{meetingsData.meetingbanner.banner_title}</li>
                        </ul>
                  </div>
                </div>
              </div>
          </div>
        </div>
        </>
        }


{
    meetingsData?.upcomingmeeting &&
        <>

        <div className="doctors-details-area pt-100 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="meeting-title">
                <h1>Upcoming Meeting</h1>
              </div>
            </div>
          </div>
          <div className="row">
          <div className="col-lg-8">
              <div className="doctors-detailss">
                <div className="doctors-history meeting-sec">
                  <h2>{meetingsData.upcomingmeeting.meeting_title}</h2>
                  <p className="meet-cal-sec float-meet"><CalendarMonthIcon />{meetingsData.upcomingmeeting.meeting_start_date_display} to {meetingsData.upcomingmeeting.meeting_end_date_display} </p>

                  <p className="meeting-about">
                  {meetingsData.upcomingmeeting.meeting_des}
                  </p>




                  <div className="row borders">
                    <div className="col-lg-3 pl-0">
                      <div className="left-title">
                        <h3>Program chairs</h3>
                      </div>
                    </div>

                    <div className="col-lg-9">
                      <div className="right-title">
                        <ul>
                          <li>
                            <i className="bx bxs-hand-right"></i>
                            {meetingsData.upcomingmeeting.meeting_chairs}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="row borders">
                    <div className="col-lg-3 pl-0">
                      <div className="left-title">
                        <h3>Program</h3>
                      </div>
                    </div>

                    <div className="col-lg-9">
                      <div className="right-title">
                        <ul>
                          <li className="pdf-color"   onClick={() => handleDownloadFile(meetingsData.upcomingmeeting.meeting_id,meetingsData.upcomingmeeting.meeting_programs)} >
                            <i className="bx bxs-hand-right"></i>
                            Download PDF <PictureAsPdfIcon />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>



                  <div className="button-sec-register">
                    <Link to="/register"><button className="regis-btn default-btn ">Register Now</button></Link>
                    </div>
                </div>


              </div>
            </div>
            <div className="col-lg-4">
              <div className="doctors-sidebar doct-meet-sidebar">
                <div className="doctors-img">
                <img src={meetingsData.upcomingmeeting.meeting_image} alt="Image" />

                  <ul>
                  {meetingsData.upcomingmeeting.meeting_facebook_link!=null &&
                                        <li>
                                      <a href={meetingsData.upcomingmeeting.meeting_facebook_link} target="_blank">
                                      <i className="bx bxl-facebook"></i>
                                      </a>
                                    </li>}


                   {meetingsData.upcomingmeeting.meeting_twitter_link!=null &&
                                        <li>
                                      <a href={meetingsData.upcomingmeeting.meeting_twitter_link} target="_blank">
                                      {/* <i className="bx bxl-twitter"></i> */}
                                      <img src={twiiterimg} alt="Image" className="twitt-sec"/>
                                      </a>
                                    </li>}


                                    {meetingsData.upcomingmeeting.meeting_pinterest_link!=null &&
                                        <li>
                                      <a href={meetingsData.upcomingmeeting.meeting_pinterest_link} target="_blank">
                                      <i className="bx bxl-pinterest-alt"></i>
                                      </a>
                                    </li>}
                                    {meetingsData.upcomingmeeting.meeting_instagram_link!=null &&
                                        <li>
                                      <a href={meetingsData.upcomingmeeting.meeting_instagram_link} target="_blank">
                                      <i className="bx bxl-instagram"></i>
                                      </a>
                                    </li>}



                  </ul>
                </div>


              </div>
            </div>


          </div>












        </div>
      </div>

        </>
        }

{/* past meeting section start */}

<div className="doctors-details-area prev-meet ptb-40">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="meeting-title">
                <h1>Previous Meeting</h1>
              </div>
            </div>
          </div>


          { currentPageData.length > 0 ? <>



              <>
              {   currentPageData.map((item,index)=>(

                  <div className={`prev-sec-inner ${index!=0  && 'margin-prev'}`}>
                        <div className="row">
                            <div className="col-md-12">
                              <div className="prev-meet-main">
                                <div className="prev-meet-sec-left">
                                  <h3>{item.meeting_start_date_firsttitledisplay}</h3>
                                  <p>{item.meeting_start_date_secondtitledisplay}</p>
                                </div>
                                <div className="prev-meet-sec-right">
                                  <h4>{item.meeting_title}  <p className="meet-cal-sec float-meet" style={{marginTop:'9px'}}><CalendarMonthIcon />{item.meeting_start_date_display} to {item.meeting_end_date_display} </p></h4>
                                  <p>{item.meeting_description}
                                    <span>Learn More</span>
                                  </p>
                                </div>
                                <div className="prev-meet-right">
                                  <button className="default-btn dwn-pdf"  onClick={() => handleDownloadprevFile(item.meeting_id,item.meeting_pdf)}><PictureAsPdfIcon />Download Pdf</button>
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>


              ))}



            </>
            </>:<> </>

            }






    </div>
    </div>

{/* past meetiing section end        */}

    <div className="container">
          <div className="row">
            <div className="col-lg-12">
            <Pagination
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                initialPage={currentPage}
                className={'page-margin'}
                onChange={handlePageChange}
              />
            </div>
        </div>
    </div>


      {/* <Subscribe /> */}

      <Footer />
      </>
      }

      {loader ? <Preloader /> : null}
    </>
  );
};

export default DoctorDetails;
