import React from 'react';
import NavbarFive from "../components/Common/NavbarFive";
import aboutimg from '../styles/img/appointment-bg4.jpg';
import Preloader from "./Common/Preloader";
import Footer from "../components/Common/Footer";
import benemembership from '../styles/img/member-benefits.png';
import { useSelector } from "react-redux";
import { isComingSoon } from "../utils/helper";
import ComingSoon from "./Common/ComingSoon";
const Benefitsmembership = () => {
  const { membershipData,homeData } = useSelector((state) => state.pageContents);
  const [loader, setLoader] = React.useState(true);
  React.useEffect(() => {
    setTimeout(() => setLoader(false), 1500);
  }, []);
  return (
    <>
    {
        homeData?.comingsoon && isComingSoon(homeData.comingsoon, 'Benefits of Membership') ?
          <ComingSoon />
          :
          <>

      <NavbarFive />
      <div className="page-title-area" style={{ backgroundImage: `url('${membershipData?.membershipbanner?.banner_image}')` }}>
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Membership</h2>
                <ul>
                  <li><a href="/">Home</a></li>
                  <li className="active">{membershipData?.membershipbanner?.banner_title}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="how-its-work ptb-100">
        <div className="container">
          <div className="section-title bene-title">
            {/* <span className="top-title">Woks</span> */}
            <h2>{membershipData?.membershipbenefit?.benefit_title}</h2>
            <p>
            {membershipData?.membershipbenefit?.benefit_short_desc}
            </p>

          </div>

          <div className="row ">
            <div className="col-lg-6" style={{ marginRight: '20px' }}>
              <div className="how-its-work-content bene-wrap-content">
                <div className="content-wrap bene-wrap">
                  <h3>{membershipData?.membershipbenefit?.benefit_details_one}</h3>
                </div>

                <div className="content-wrap bene-wrap">
                  <h3>{membershipData?.membershipbenefit?.benefit_details_second}</h3>
                </div>

                <div className="content-wrap bene-wrap">
                  <h3>{membershipData?.membershipbenefit?.benefit_details_third}</h3>
                </div>

                <div className="content-wrap bene-wrap">
                  <h3>{membershipData?.membershipbenefit?.benefit_details_forth}</h3>
                </div>
                <div className="content-wrap bene-wrap">
                  <h3>{membershipData?.membershipbenefit?.benefit_details_fifth}</h3>
                </div>
                <div className="content-wrap bene-wrap">
                  <h3>{membershipData?.membershipbenefit?.benefit_details_six}</h3>
                </div>
                {/* <Link href="#" className="default-btn">
                  Know Details
                </Link> */}
              </div>
            </div>

            <div className="col-lg-5">
              <div className="how-work-img">
                <img
                  src={membershipData?.membershipbenefit?.image}
                  alt="Image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>


      <Footer />
      </>
      }
      {loader ? <Preloader /> : null}
    </>
  )
}
export default Benefitsmembership;
