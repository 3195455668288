import React from "react";
import facebookimg from '../../styles/img/facebook_new.png';
import twitterimg from '../../styles/img/twitter-logo.png';
import { useSelector } from "react-redux";

const TopHeader = () => {
  const { homeData } = useSelector((state) => state.pageContents);


  return (
    <>
         <div className="top-header-area">
        <div className="container header-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-sm-12 ">
              <ul className="header-content-left header-conts-left">
                <li className="top-heading-left">
                  {/* <i className="bx bx-time"></i> */}
                  Society for Maternal-Fetal Anesthesia
                </li>
                {/* <li>
                  <a href="tel:+822456974">
                    <i className="bx bx-phone-call"></i>
                    Call Us: +821-456-241
                  </a>
                </li>
                <li>
                  <a href="mailto:hello@info.com">
                    <i className="bx bxs-paper-plane"></i>
                    Email: hello@info.com
                  </a>
                </li> */}
              </ul>

              {/* <div className="search-sec">
              <form role="search" method="get" className="search-form form" action="">
                    <label>
                        <span className="screen-reader-text">Search for...</span>
                        <input type="search" className="search-field ss-search" placeholder="Search Here" value="" name="s" title="" />
                    </label>
                    <input type="submit" className="search-submits button-srch" value="Search" />
                </form>
              </div> */}
            </div>
              <div className="col-md-5  col-md-5 col-sm-10">
              <div className="search-sec">
                <form role="search" method="get" className="search-form form" action="">
                    <label>
                        <span className="screen-reader-text">Search for...</span>
                        <input type="search" className="search-field ss-search" placeholder="Search Here" name="" title="" />
                    </label>
                    <input type="submit" className="search-submits button-srch" value="Search" />
                </form>
                <div className="search-sec-ul">
                {/* <ul className="header-content-right">
                <li>
                  <a href="https://www.facebook.com/" target="_blank">

                    <img src={facebookimg} />
                  </a>
                </li>
                <li>
                  <a href="https://www.twitter.com/" target="_blank">

                    <img src={twitterimg} />
                  </a>
                </li>

              </ul> */}
              </div>

              </div>

                </div>


           <div className="col-lg-1 col-md-1 col-sm-2">
              <ul className="header-content-right">
                {/* <li>
                  <a href="https://www.facebook.com/" target="_blank">

                    <img src={facebookimg} />
                  </a>
                </li> */}
                 {
              homeData?.widgetdata &&
              <li>
              <a href={homeData.widgetdata.footer_twitter_link} target="_blank">

                <img src={twitterimg} />
              </a>
            </li>
                  }


              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopHeader;
