import CircularProgress from "@mui/material/CircularProgress";

const PageContentLoader = ({ message }) => {
  return (
    <div className="page_content_loader_overlay">
      <div className="page_content_loader">
        <div className="text-center">
          <CircularProgress />
        </div>
        {message ? <p>{message}</p> : <p>Processing...</p>}
      </div>
    </div>
  );
};

export default PageContentLoader;
