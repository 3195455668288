import React, { useState, useEffect, useRef } from "react";
import NavbarFive from "./Common/NavbarFive";
import Footer from "../components/Common/Footer";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormHelperText from "@mui/material/FormHelperText";
import { Link } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import infoimg from "../styles/img/info.png";
import {
  generateUniqueToken,
  updateNullOfObjectValues,
  isEmail,
} from "./../utils/helper";
import {
  registationSubmit,
  getExistingRegistation,
} from "./../actions/pagecontent.actions";
import { useSelector, useDispatch } from "react-redux";
import { setFormToken } from "./../features/userdataSlice";
import Preloader from "./Common/Preloader";
import classNames from "classnames";
import PaymentForm from "./Common/PaymentForm";
import { createPaymentIntent } from "../actions/payment.actions";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PageContentLoader from "./Common/PageContentLoader";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Register = () => {
  const formFields = {
    title: "Dr.",
    first_name: "",
    last_name: "",
    prefferd_name: "",
    degree: "",
    institution: "",
    city: "",
    state: "",
    country: "",
    email: "",
    phone_number: "",
    friday_night: "",
    saturday_night: "",
    dietary_restrictions: "",
    lactation_room: "",
    meeting_more: "",
  };
  const timerRef = useRef(null);
  const registrationFormRef = useRef(null);
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState(formFields);
  const [formErrors, setFormErrors] = useState(
    updateNullOfObjectValues(formFields)
  );
  const { formToken, ip_address } = useSelector((state) => state.userdata);
  const [loader, setLoader] = React.useState(true);
  const [paymentDialog, setPaymentDialog] = React.useState(false);
  const [formErrorMsg, setFormErrorMsg] = React.useState("");
  const [submitProgrogress, setSubmitProgrogress] = React.useState(false);
  const [paymentAmount, setPaymentAmount] = React.useState(0);
  const [clientSecret, setClientSecret] = React.useState("");
  const [isPaymentProcessing, setIsPaymentProcessing] = React.useState(false);

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: null });
  };

  const checkObjectKeysHaveValue = (obj) => {
    return Object.values(obj).some((value) => !!value);
  };

  useEffect(() => {
    if (formToken) {
      getExistingRegistation({ token: formToken })
        .then((res) => {
          if (res.data.status) {
            let newFormValues = { ...formValues };
            Object.entries(formValues).forEach(([key, value]) => {
              if (res.data.data.registration[key]) {
                newFormValues[key] = res.data.data.registration[key];
              }
            });
            setFormValues(newFormValues);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    setTimeout(() => setLoader(false), 1500);
  }, []);

  const saveFormValues = (plan_type, amount) => {
    setFormErrorMsg("");
    if (!formValidate()) {
      registrationFormRef.current.scrollIntoView();
      return;
    }

    setSubmitProgrogress(true);
    registationSubmit({
      ...formValues,
      token: formToken,
      ip_address: ip_address,
      plan_type: plan_type,
      payment_amount: amount,
    })
      .then((res) => {
        if (res.data.status) {
          setFormErrorMsg("");
          loadClientSecret(res.data.data.reg_id, amount);
        } else {
          setFormErrorMsg(res.data.message);
          setSubmitProgrogress(false);
        }
      })
      .catch((err) => {
        setFormErrorMsg("Something went wrong, please try again later.");
        setSubmitProgrogress(false);
      });
  };

  const formValidate = () => {
    let errors = {};
    let isValid = true;
    if (!formValues.title) {
      errors.title = "Required";
      isValid = false;
    }
    if (!formValues.first_name) {
      errors.first_name = "This field is required";
      isValid = false;
    }
    if (!formValues.last_name) {
      errors.last_name = "This field is required";
      isValid = false;
    }
    if (!formValues.prefferd_name) {
      errors.prefferd_name = "This field is required";
      isValid = false;
    }
    if (!formValues.degree) {
      errors.degree = "This field is required";
      isValid = false;
    }
    if (!formValues.institution) {
      errors.institution = "This field is required";
      isValid = false;
    }
    if (!formValues.city) {
      errors.city = "This field is required";
      isValid = false;
    }
    if (!formValues.state) {
      errors.state = "This field is required";
      isValid = false;
    }
    if (!formValues.country) {
      errors.country = "This field is required";
      isValid = false;
    }
    if (!formValues.email) {
      errors.email = "This field is required";
      isValid = false;
    } else if (!isEmail(formValues.email)) {
      errors.email = "Invalid email address";
      isValid = false;
    }
    if (!formValues.phone_number) {
      errors.phone_number = "This field is required";
      isValid = false;
    }
    if (!formValues.friday_night) {
      errors.friday_night = "This field is required";
      isValid = false;
    }
    if (!formValues.saturday_night) {
      errors.saturday_night = "This field is required";
      isValid = false;
    }
    if (!formValues.lactation_room) {
      errors.lactation_room = "This field is required";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handlePayNow = (e, amt, plan_type) => {
    e.preventDefault();

    if (submitProgrogress) return;

    setPaymentAmount(amt);
    saveFormValues(plan_type, amt);
    // const token = generateUniqueToken();
    // dispatch(setFormToken(token));
    // setFormValues(formFields);
    // window.open(url, "_blank");
  };

  const handlePaymentDialogClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;

    if(isPaymentProcessing) return;
    setPaymentDialog(false);
  };

  const loadClientSecret = (reg_id, amount) => {
    createPaymentIntent({ amount: amount, reg_id: reg_id, payment_type: "register_member" })
      .then((res) => {
        if (res.data.status) {
          setClientSecret(res.data.data.client_secret);
          setPaymentDialog(true);
          setSubmitProgrogress(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePaymentProcess = (d) => {
    setIsPaymentProcessing(d);
  };

  return (
    <>
      <NavbarFive />
      <div
        className="page-title-area"
        style={{
          backgroundImage: `url('https://s3-dev-sms.s3.ap-south-1.amazonaws.com/uploads/1719216928_appointment bg4.jpg`,
        }}
      >
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Registration</h2>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li className="active">Registration</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="jarallax ptb-50 price-top" ref={registrationFormRef}>
        <div className="container">
          <div className="appointment-here-form membership-appli membersp-main-sec regis-sec-min">
            <h2>Annual Meeting Registration</h2>
            <form>
              <div className="row">
                <div className="col-lg-4 col-sm-4 width-full">
                  <div className="row">
                    <div className="col-lg-3 col-sm-3 pading-no">
                      <label className="mem-sec-lable">
                        Title<span className="redcolr">*</span>
                      </label>
                      <div className="form-group form-member">
                        <select
                          className={classNames("form-control sel-memb", {
                            "is-invalid": formErrors.title,
                          })}
                          name="title"
                          onChange={handleChange}
                          value={formValues.title}
                        >
                          <option value="Dr.">Dr.</option>
                          <option value="Mr.">Mr.</option>
                          <option value="Mrs.">Mrs.</option>
                          <option value="Ms.">Ms.</option>
                        </select>
                        {formErrors.title && (
                          <div className="invalid-feedback">
                            {formErrors.title}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-9 col-sm-9 ">
                      <label className="mem-sec-lable">
                        First Name<span className="redcolr">*</span>
                      </label>
                      <div className="form-group">
                        <input
                          type="text"
                          className={classNames("form-control", {
                            "is-invalid": formErrors.first_name,
                          })}
                          id="firstname"
                          placeholder="Enter Your First Name"
                          name="first_name"
                          onChange={handleChange}
                          value={formValues.first_name}
                        />
                        {formErrors.first_name && (
                          <div className="invalid-feedback">
                            {formErrors.first_name}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-sm-4 width-full">
                  <label className="mem-sec-lable">
                    Last Name<span className="redcolr">*</span>
                  </label>
                  <div className="form-group">
                    <input
                      type="text"
                      className={classNames("form-control", {
                        "is-invalid": formErrors.last_name,
                      })}
                      id="lastname"
                      placeholder="Enter Your Last Name"
                      name="last_name"
                      onChange={handleChange}
                      value={formValues.last_name}
                    />
                    {formErrors.last_name && (
                      <div className="invalid-feedback">
                        {formErrors.last_name}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-sm-4 width-full">
                  <label className="mem-sec-lable">
                    Preferred Name<span className="redcolr">*</span>
                  </label>
                  <div className="form-group">
                    <input
                      type="text"
                      className={classNames("form-control", {
                        "is-invalid": formErrors.prefferd_name,
                      })}
                      id="preferredname"
                      placeholder="Enter Your Preferred Name"
                      name="prefferd_name"
                      onChange={handleChange}
                      value={formValues.prefferd_name}
                    />
                    {formErrors.prefferd_name && (
                      <div className="invalid-feedback">
                        {formErrors.prefferd_name}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row mem-margn-sec">
                <div className="col-lg-4 col-sm-4 width-full">
                  <label className="mem-sec-lable">
                    Degrees<span className="redcolr">*</span>
                  </label>
                  <div className="form-group">
                    <input
                      type="text"
                      className={classNames("form-control", {
                        "is-invalid": formErrors.degree,
                      })}
                      id="preferredname"
                      placeholder="Enter Your Degrees"
                      name="degree"
                      onChange={handleChange}
                      value={formValues.degree}
                    />
                    {formErrors.degree && (
                      <div className="invalid-feedback">
                        {formErrors.degree}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-sm-4 width-full">
                  <label className="mem-sec-lable">
                    Institution<span className="redcolr">*</span>
                  </label>
                  <div className="form-group">
                    <input
                      type="text"
                      className={classNames("form-control", {
                        "is-invalid": formErrors.institution,
                      })}
                      id="preferredname"
                      placeholder="Enter Your Institution"
                      name="institution"
                      onChange={handleChange}
                      value={formValues.institution}
                    />
                    {formErrors.institution && (
                      <div className="invalid-feedback">
                        {formErrors.institution}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4 width-full">
                  <label className="mem-sec-lable">
                    City<span className="redcolr">*</span>
                  </label>
                  <div className="form-group">
                    <input
                      type="text"
                      className={classNames("form-control", {
                        "is-invalid": formErrors.city,
                      })}
                      id="preferredname"
                      placeholder="Enter Your City"
                      name="city"
                      onChange={handleChange}
                      value={formValues.city}
                    />
                    {formErrors.city && (
                      <div className="invalid-feedback">{formErrors.city}</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row mem-margn-sec">
                <div className="col-lg-4 col-sm-4 width-full">
                  <label className="mem-sec-lable">
                    State<span className="redcolr">*</span>
                  </label>
                  <div className="form-group">
                    <input
                      type="text"
                      className={classNames("form-control", {
                        "is-invalid": formErrors.state,
                      })}
                      id="state"
                      placeholder="Enter Your State"
                      name="state"
                      onChange={handleChange}
                      value={formValues.state}
                    />
                    {formErrors.state && (
                      <div className="invalid-feedback">{formErrors.state}</div>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-sm-4 width-full">
                  <label className="mem-sec-lable">
                    Country<span className="redcolr">*</span>
                  </label>
                  <div className="form-group">
                    <input
                      type="text"
                      className={classNames("form-control", {
                        "is-invalid": formErrors.country,
                      })}
                      id="preferredname"
                      placeholder="Enter Your Country"
                      name="country"
                      onChange={handleChange}
                      value={formValues.country}
                    />
                    {formErrors.country && (
                      <div className="invalid-feedback">
                        {formErrors.country}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-sm-4 width-full">
                  <label className="mem-sec-lable">
                    Email<span className="redcolr">*</span>
                  </label>
                  <div className="form-group">
                    <input
                      type="text"
                      className={classNames("form-control", {
                        "is-invalid": formErrors.email,
                      })}
                      id="email"
                      placeholder="Enter Your Email"
                      name="email"
                      onChange={handleChange}
                      value={formValues.email}
                    />
                    {formErrors.email && (
                      <div className="invalid-feedback">{formErrors.email}</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row mem-margn-sec">
                <div className="col-lg-4 col-sm-4 width-full">
                  <label className="mem-sec-lable">
                    Phone Number<span className="redcolr">*</span>
                  </label>
                  <div className="form-group">
                    <input
                      type="text"
                      className={classNames("form-control", {
                        "is-invalid": formErrors.phone_number,
                      })}
                      id="phone_number"
                      placeholder="Enter Your Phone Number"
                      name="phone_number"
                      onChange={handleChange}
                      value={formValues.phone_number}
                    />
                    {formErrors.phone_number && (
                      <div className="invalid-feedback">
                        {formErrors.phone_number}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mem-margn-sec">
                <div className="col-lg-12 col-sm-12 member-selct mem-registr">
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      I plan to attend the Welcome Reception on Friday night
                      <span className="redcolr">*</span>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="friday_night"
                      value={formValues.friday_night}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No"
                        className="no-sec-mem"
                      />
                    </RadioGroup>
                  </FormControl>
                  {formErrors.friday_night && (
                    <FormHelperText className="manual_error">
                      {formErrors.friday_night}
                    </FormHelperText>
                  )}
                </div>
              </div>
              <div className="row mem-margn-sec">
                <div className="col-lg-12 col-sm-12 member-selct mem-registr pln-mem-regis">
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      I plan to attend the Mixer on Saturday night
                      <span className="redcolr">*</span>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="saturday_night"
                      value={formValues.saturday_night}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No"
                        className="no-sec-mem"
                      />
                    </RadioGroup>
                  </FormControl>
                  {formErrors.saturday_night && (
                    <FormHelperText className="manual_error">
                      {formErrors.saturday_night}
                    </FormHelperText>
                  )}
                </div>
              </div>

              <div className="row mem-margn-sec">
                <div className="col-lg-12 col-sm-12 member-selct mem-registr lac-rom-sec">
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      I would like access to a lactation room
                      <span className="redcolr">*</span>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="lactation_room"
                      value={formValues.lactation_room}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No"
                        className="no-sec-mem"
                      />
                    </RadioGroup>
                  </FormControl>
                  {formErrors.lactation_room && (
                    <FormHelperText className="manual_error">
                      {formErrors.lactation_room}
                    </FormHelperText>
                  )}
                </div>
              </div>

              <div className="row mem-margn-sec" style={{ marginTop: "25px" }}>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group vali-sec side-sec">
                    <label>I have the following dietary restrictions:</label>
                    <textarea
                      cols="30"
                      rows="3"
                      placeholder=""
                      name="dietary_restrictions"
                      onChange={handleChange}
                      value={formValues.dietary_restrictions}
                      className={classNames("form-control", {
                        "is-invalid": formErrors.dietary_restrictions,
                      })}
                    />
                    {formErrors.dietary_restrictions && (
                      <div className="invalid-feedback">
                        {formErrors.dietary_restrictions}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group vali-sec larg-secs">
                    <label>
                      Is there anything else we should know to make the meeting
                      more accessible to you?
                    </label>
                    <textarea
                      cols="30"
                      rows="3"
                      placeholder=""
                      name="meeting_more"
                      onChange={handleChange}
                      value={formValues.meeting_more}
                      className={classNames("form-control", {
                        "is-invalid": formErrors.meeting_more,
                      })}
                    />
                    {formErrors.meeting_more && (
                      <div className="invalid-feedback">
                        {formErrors.meeting_more}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
            <div className="validate-sec">
              {formErrorMsg && (
                <div className="">
                  <p className="error-msg" style={{ top: "20px" }}>
                    {formErrorMsg}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* plan section start */}
        <div className="container-fluid">
          <div className="price-sec">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <h2>Pricing Plans </h2>
              </div>
            </div>
            <div className="price-main-sec">
              <div className="row">
                <div className="col-lg-3 col-sm-3 price-mains">
                  <div className="price-main-secc">
                    <div className="price-sec-tab">
                      <h4>Member</h4>

                      <p>$200</p>
                    </div>

                    <div className="price-tab-sec pric-first-tab">
                      <p>Annual Fall Meeting Registration (Member)</p>
                    </div>
                  </div>
                  <Link
                    to="#"
                    onClick={(e) => handlePayNow(e, 200, "member")}
                    className="a-sec"
                  >
                    <button className="pay-btn pay-pos">Pay Now</button>
                  </Link>
                </div>

                <div className="col-lg-3 col-sm-3 price-mains price-mains-non-mem">
                  <div className="price-main-secc">
                    <div className="price-sec-tab price-non-mem">
                      <h4>Non-Member</h4>

                      <p>$350 </p>
                    </div>

                    <div className="price-tab-sec pric-sec-tab">
                      <p>Annual Fall Meeting Registration (Non-Member)</p>
                    </div>
                  </div>
                  <Link
                    to="#"
                    onClick={(e) => handlePayNow(e, 350, "non_member")}
                    className="a-sec"
                  >
                    <button className="pay-btn pay-btn-non">Pay Now</button>
                  </Link>
                </div>

                <div className="col-lg-3 col-sm-3 price-mains price-mains-stud">
                  <div className="price-main-secc">
                    <div className="price-sec-tab price-non-stud">
                      <h4>Student, Resident, Fellow</h4>

                      <p>$100</p>
                    </div>

                    <div className="price-tab-sec ">
                      <p>
                        Annual Fall Meeting Registration (Student, Resident,
                        Fellow)
                      </p>
                    </div>
                  </div>
                  <Link
                    to="#"
                    onClick={(e) =>
                      handlePayNow(e, 100, "student_resident_fellow")
                    }
                    className="a-sec"
                  >
                    {" "}
                    <button className="pay-btn pay-btn-std">Pay Now</button>
                  </Link>
                </div>

                <div className="col-lg-3 col-sm-3 price-mains price-mains-actv">
                  <div className="price-main-secc">
                    <div className="price-sec-tab price-actv">
                      <h4>Active and affiliate members</h4>

                      <p>$200</p>
                    </div>

                    <div className="price-tab-sec">
                      <p>
                        2024 Annual SMFA Membership Dues for active and
                        affiliate members
                      </p>
                    </div>
                  </div>
                  <Link
                    to="#"
                    onClick={(e) =>
                      handlePayNow(e, 200, "active_affiliate_member")
                    }
                    className="a-sec"
                  >
                    <button className="pay-btn pay-btn-atv">Pay Now</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* plan section end */}

        <Dialog
          fullWidth
          maxWidth={"sm"}
          open={paymentDialog}
          onClose={handlePaymentDialogClose}  className="strip-main-sec"
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
          ></DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handlePaymentDialogClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            className="strip-pay-sec"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent >
            {clientSecret && (
              <Elements
                stripe={stripe}
                options={{
                  clientSecret,
                  theme: "stripe",
                }}
              >
                <PaymentForm
                  amount={paymentAmount}
                  clientSecret={clientSecret}
                  onPaymentProcess={handlePaymentProcess}
                  successParams={{ payment_type: 'register_member', registration_token: formToken }}
                />
              </Elements>
            )}
          </DialogContent>
        </Dialog>
        {submitProgrogress && <PageContentLoader />}
      </div>
      {loader ? <Preloader /> : null}
      <Footer />
    </>
  );
};

export default Register;
