import React from 'react';
import NavbarFive from "../components/Common/NavbarFive";
import aboutimg from '../styles/img/appointment-bg4.jpg';
import Preloader from "./Common/Preloader";
import Footer from "../components/Common/Footer";
import benemembership from '../styles/img/member-benefits.png';
import { useSelector } from "react-redux";
import { isComingSoon } from "../utils/helper";
import ComingSoon from "./Common/ComingSoon";
const Cancelpolicy = () => {
  const { cancelpolicyData,homeData } = useSelector((state) => state.pageContents);
  const [loader, setLoader] = React.useState(true);
  React.useEffect(() => {
    setTimeout(() => setLoader(false), 1500);
  }, []);

  console.log(cancelpolicyData);
  return (
    <>
    {
        homeData?.comingsoon && isComingSoon(homeData.comingsoon, 'Cancellation Policy') ?
          <ComingSoon />
          :
          <>

      <NavbarFive />
      <div className="page-title-area" style={{ backgroundImage: `url('${cancelpolicyData?.banner_image_display}')` }}>
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>{cancelpolicyData?.banner_title}</h2>
                <ul>
                  <li><a href="/">Home</a></li>
                  <li className="active">{cancelpolicyData?.banner_title}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="how-its-work ptb-100">
        <div className="container">

            <p>
            <div dangerouslySetInnerHTML={{ __html: cancelpolicyData?.policy }}></div>


            </p>




        </div>
      </div>


      <Footer />
      </>
      }
      {loader ? <Preloader /> : null}
    </>
  )
}
export default Cancelpolicy;
