import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavbarFive from "../components/Common/NavbarFive";
import FunFactStyleOne from "../components/Common/FunFactStyleOne";
import Footer from "../components/Common/Footer";
import PageBanner from "../components/Common/PageBanner";
import doctorone from "../styles/img/home/home-six-doctor1.png";
import doctortwo from "../styles/img/home/home-six-doctor2.png";
import doctorthree from "../styles/img/home/home-six-doctor3.png";

import doctoreleven from "../styles/img/doctor11.png";
import doctortwelve from "../styles/img/doctor12.png";
import doctorsixteen from "../styles/img/doctor16.png";
import doctorseventeen from "../styles/img/doctor17.png";
import misionvision from "../styles/img/mission_vision_img.png";
import prorammeimg from "../styles/img/programme_img.jpg";
import whoimage from "../styles/img/home/home-six-service3.jpg";
import Preloader from "./Common/Preloader";

import membershipimg from "../styles/img/member-ship.jpg";
import aboutimg from "../styles/img/appointment-bg4.jpg";
import { useSelector } from "react-redux";
import ReadMore from "./Common/ReadMore";
import { isComingSoon, isEmail } from "../utils/helper";
import ComingSoon from "./Common/ComingSoon";
import { registationSubmit } from "./../actions/pagecontent.actions";
import classNames from "classnames";

const AboutUs = () => {
  const [loader, setLoader] = React.useState(true);
  const { aboutData, homeData } = useSelector((state) => state.pageContents);
  const formToken = useSelector((state) => state.userdata.formToken);
  const navigate = useNavigate();
  React.useEffect(() => {
    setTimeout(() => setLoader(false), 1500);
  }, []);
  var new_director = [];
  var limitloc = 3;
  var temploc = [];
  if (aboutData?.directorsdata) {
    for (let i = 0; i < aboutData.directorsdata.length; i++) {
      if (i < limitloc) {
        temploc.push(aboutData.directorsdata[i]);
      } else {
        new_director.push(temploc);
        temploc = [];
        temploc.push(aboutData.directorsdata[i]);

        limitloc = limitloc + new_director[0].length;
      }
      if (i == aboutData.directorsdata.length - 1) {
        new_director.push(temploc);
      }
    }
  }

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();

    setEmailError("");
    if (email === "") {
      setEmailError("Please enter email");
      return;
    } else if (isEmail(email) === false) {
      setEmailError("Please enter valid email");
      return;
    }

    navigate("/onlineapplication?email=" + email);
  };

  return (
    <>
      {homeData?.comingsoon && isComingSoon(homeData.comingsoon, "About") ? (
        <ComingSoon />
      ) : (
        <>
          {aboutData?.aboutbanner && (
            <>
              <div
                className="page-title-area"
                style={{
                  backgroundImage: `url('${aboutData.aboutbanner.banner_image}')`,
                }}
              >
                <div className="d-table">
                  <div className="d-table-cell">
                    <div className="container">
                      <div className="page-title-content">
                        <h2>{aboutData.aboutbanner.banner_title}</h2>
                        <ul>
                          <li>
                            <a href="/">Home</a>
                          </li>
                          <li className="active">
                            {aboutData.aboutbanner.banner_title}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <NavbarFive />

          <div className="about-area about-page">
            {aboutData?.aboutWho && (
              <>
                <div className="container">
                  <div className="col-md-12">
                    <div className="about-top-sec">
                      <h2>{aboutData.aboutWho.section_title}</h2>
                    </div>
                  </div>
                </div>

                <div
                  className="container margin-bottoms"
                  style={{ marginBottom: "100px" }}
                >
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="about-img">
                        <img
                          src={aboutData.aboutWho.section_image}
                          alt="Image"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="about-content">
                        <h2 className="about-mid-cont about-mid-conts">
                          {aboutData.aboutWho.section_sub_title}{" "}
                        </h2>
                        <p>{aboutData.aboutWho.section_description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {aboutData?.aboutMember && (
              <>
                <div className="ours-doctors-area">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="doctors-content ptb-100">
                          <h2 className="about-mid-conts">
                            {aboutData.aboutMember.section_title}
                          </h2>
                          <p style={{ whiteSpace: "pre-line" }}>
                            {aboutData.aboutMember.section_description}
                          </p>

                          <Link to="/onlineapplication" className="default-btn">
                            Join Now
                          </Link>
                        </div>
                      </div>

                      <div className="col-lg-6 pr-0">
                        <div
                          className="doctors-img "
                          style={{
                            backgroundImage: `url('${aboutData.aboutMember.section_image}')`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/*{
                aboutData?.aboutProgram &&
                <>


                  <div className="container">
                    <div className="row align-items-center about-margin-top" >
                      <div className="col-lg-6">
                        <div className="about-img">
                          <img src={aboutData.aboutProgram.section_image} alt="Image" />

                        </div>
                      </div>

                      <div className="col-lg-6" >
                        <div className="about-content" style={{ paddingTop: '25px' }}>

                          <h2 className="about-mid-conts">{aboutData.aboutProgram.section_title}

                          </h2>


                          <ul>
                            <li>
                              <i className="flaticon-tick"></i>
                              {aboutData.aboutProgram.section_sub_title_first}
                              <p style={{ fontWeight: '400' }}>      {aboutData.aboutProgram.section_sub_short_first}</p>
                            </li>
                            <li>
                              <i className="flaticon-tick"></i>  {aboutData.aboutProgram.section_sub_title_second}
                              <p style={{ fontWeight: '400' }}>     {aboutData.aboutProgram.section_sub_short_second}</p>
                            </li>
                            <li>
                              <i className="flaticon-tick"></i>
                              {aboutData.aboutProgram.section_sub_title_third}
                              <p style={{ fontWeight: '400' }}>    {aboutData.aboutProgram.section_sub_short_third}</p>
                            </li>

                          </ul>
                          <Link href="/" className="default-btn" style={{ marginTop: '10px', marginLeft: '30px' }}>
                            Donate
                          </Link>
                        </div>
                      </div>
                    </div>

                  </div>
                </>

              }*/}
          </div>

          {aboutData?.aboutMission && (
            <>
              <div
                className="about-areass two ptb-100"
                style={{ backgroundColor: "#f9fbfc", paddingTop: "70px" }}
              >
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="about-content">
                        {/* <span className="top-title">About Corona</span> */}
                        <h2 className="about-mid-conts">
                          {" "}
                          {aboutData.aboutMission.section_title}
                        </h2>
                        {aboutData.aboutMission.section_description && (
                          <p>{aboutData.aboutMission.section_description}</p>
                        )}

                        <strong>
                          {aboutData.aboutMission.section_sub_title}
                        </strong>

                        {(aboutData.aboutMission.section_list_first ||
                          aboutData.aboutMission.section_list_second ||
                          aboutData.aboutMission.section_list_third ||
                          aboutData.aboutMission.section_list_four ||
                          aboutData.aboutMission.section_list_five ||
                          aboutData.aboutMission.section_list_six ||
                          aboutData.aboutMission.section_list_seven) && (
                          <ul>
                            {aboutData.aboutMission.section_list_first && (
                              <li>
                                <i className="flaticon-tick"></i>
                                {aboutData.aboutMission.section_list_first}
                              </li>
                            )}
                            {aboutData.aboutMission.section_list_second && (
                              <li>
                                <i className="flaticon-tick"></i>
                                {aboutData.aboutMission.section_list_second}
                              </li>
                            )}
                            {aboutData.aboutMission.section_list_third && (
                              <li>
                                <i className="flaticon-tick"></i>
                                {aboutData.aboutMission.section_list_third}
                              </li>
                            )}
                            {aboutData.aboutMission.section_list_four && (
                              <li>
                                <i className="flaticon-tick"></i>
                                {aboutData.aboutMission.section_list_four}
                              </li>
                            )}
                            {aboutData.aboutMission.section_list_five && (
                              <li>
                                <i className="flaticon-tick"></i>
                                {aboutData.aboutMission.section_list_five}
                              </li>
                            )}
                            {aboutData.aboutMission.section_list_six && (
                              <li>
                                <i className="flaticon-tick"></i>
                                {aboutData.aboutMission.section_list_six}
                              </li>
                            )}
                            {aboutData.aboutMission.section_list_seven && (
                              <li>
                                <i className="flaticon-tick"></i>
                                {aboutData.aboutMission.section_list_seven}
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="about-img">
                        <img
                          src={aboutData.aboutMission.section_image}
                          alt="Image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* <DoctorsStyleOne /> */}

          {/* doctor style section start */}
          {aboutData?.aboutdirectors?.section_title &&
            aboutData?.directorsdata?.length && (
              <div className="doctors-area-two six pt-100 pb-90">
                <div className="container">
                  <div className="section-title">
                    {/* <span className="top-title">Our Doctors</span> */}
                    {aboutData?.aboutdirectors && (
                      <>
                        <h2> {aboutData.aboutdirectors.section_title}</h2>
                        <p>{aboutData.aboutdirectors.section_description}</p>
                      </>
                    )}
                  </div>

                  {aboutData?.directorsdata?.length > 0 ? (
                    <>
                      {new_director.map((p, i) => (
                        <div className="row justify-content-center">
                          <>
                            {new_director[i].map((item, index) => (
                              <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="single-doctors-two">
                                  <div className="doctor-img">
                                    <img
                                      src={item.director_image}
                                      alt="Image"
                                    />

                                    <ul className="doctors-link">
                                      {item.director_facebook_link != null && (
                                        <li>
                                          <a
                                            href={item.director_facebook_link}
                                            target="_blank"
                                          >
                                            <i className="bx bxl-facebook"></i>
                                          </a>
                                        </li>
                                      )}

                                      {item.director_twitter_link != null && (
                                        <li>
                                          <a
                                            href={item.director_twitter_link}
                                            target="_blank"
                                          >
                                            <i className="bx bxl-twitter"></i>
                                          </a>
                                        </li>
                                      )}

                                      {item.director_pinterest_link != null && (
                                        <li>
                                          <a
                                            href={item.director_pinterest_link}
                                            target="_blank"
                                          >
                                            <i className="bx bxl-pinterest-alt"></i>
                                          </a>
                                        </li>
                                      )}
                                      {item.director_instagram_link != null && (
                                        <li>
                                          <a
                                            href={item.director_instagram_link}
                                            target="_blank"
                                          >
                                            <i className="bx bxl-instagram"></i>
                                          </a>
                                        </li>
                                      )}
                                    </ul>
                                  </div>

                                  <div className="doctors-content about-contets">
                                    <h3>{item.director_name}</h3>
                                    <span>{item.director_specialization}</span>

                                    <ReadMore>
                                      {item.director_short_desc}
                                    </ReadMore>
                                  </div>
                                </div>
                              </div>
                            ))}
                            {new_director[i].length == 2 && (
                              <div className="col-lg-4 col-md-4 col-sm-12 hide-sec"></div>
                            )}
                            {new_director[i].length == 1 && (
                              <>
                                <div className="col-lg-4 col-md-4 col-sm-12 hide-sec"></div>
                                <div className="col-lg-4 col-md-4 col-sm-12 hide-sec"></div>
                              </>
                            )}
                          </>
                        </div>
                      ))}
                    </>
                  ) : (
                    <> </>
                  )}
                </div>
              </div>
            )}
          {/* doctor style section end */}

          {/* <div className="about-area four abouts-six" style={{padding:'20px 0px 100px 0px'}}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <div className="about-content ml-auto">
              <span className="top-title">About Us</span>
              <h2>Free Medical Camp Importance & Objectives</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan lacus vel facilisis.
              </p>

              <ul>
                <li>
                  <i className="flaticon-tick"></i>
                  Free Registration of Deserving Cases for Surgeries
                </li>
                <li>
                  <i className="flaticon-tick"></i>
                  Free Hepatitis B & C Screening Test
                </li>
                <li>
                  <i className="flaticon-tick"></i>
                  Free Consultation & Medicine
                </li>
                <li>
                  <i className="flaticon-tick"></i>
                  Free Blood Pressure Test
                </li>
                <li>
                  <i className="flaticon-tick"></i>
                  Free Diabetes Test
                </li>
                <li>
                  <i className="flaticon-tick"></i>
                  Childbirth
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-6 pr-0">
            <div className="about-img about-img-2"></div>
          </div>
        </div>
      </div>
    </div> */}

          {/*<FunFactStyleOne />*/}

          {/* <Subscribe /> */}

          {/* subscribe section start */}

          <div className="subscribe-area">
            <div className="container">
              <div className="row align-items-center">
                <div
                  className="col-lg-6 col-md-6 col-sm-12"
                  data-aos="fade-in"
                  data-aos-delay="200"
                  data-aos-duration="1200"
                >
                  <h2 className="becom-mem">Become a Member</h2>
                  {/* <p>Get our latest news & update regularly</p> */}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <form
                    className="newsletter-form"
                    data-aos="fade-in"
                    data-aos-delay="400"
                    data-aos-duration="1200"
                    onSubmit={handleSubmit}
                  >
                    <input
                      type="email"
                      className={classNames("form-control", {
                        "is-invalid": emailError,
                      })}
                      placeholder="Enter Your Email"
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                    {emailError && (
                      <div className="invalid-feedback">{emailError}</div>
                    )}

                    <button className="default-btn" type="submit">
                      Join Now
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* subscribe section end */}

          <Footer />
        </>
      )}
      {loader ? <Preloader /> : null}
    </>
  );
};

export default AboutUs;
