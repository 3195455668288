import React, { useState, useRef } from "react";
import NavbarFive from "../components/Common/NavbarFive";
import aboutimg from "../styles/img/appointment-bg4.jpg";
import Preloader from "./Common/Preloader";
import Footer from "../components/Common/Footer";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { updateNullOfObjectValues, isEmail, objectToQueryString, generateRandomString } from "./../utils/helper";
import classNames from "classnames";
import { onlineApplicationSubmit } from "../actions/pagecontent.actions";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import PaymentForm from "./Common/PaymentForm";
import { createPaymentIntent } from "../actions/payment.actions";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PageContentLoader from "./Common/PageContentLoader";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Onlineapplication = () => {
  const formFields = {
    membership_type: "Active",
    income: "",
    title: "Dr.",
    first_name: "",
    middle_name: "",
    last_name: "",
    email_id: "",
    phone_number: "",
    institution: "",
    address: "",
    profession: "",
    profession_other: "",
    specialty: [],
    specialty_other: "",
    subspecialty: [],
    subspecialty_other: "",
    race: "",
    ethnicity: "",
    gender: "",
    pronouns: "",
    sexual_orientation: "",
    dob: "",
    language: [],
    language_other: "",
  };
  const applicationFormRef = useRef(null);
  const navigate = useNavigate();
  const { ip_address } = useSelector((state) => state.userdata);
  const [formValues, setFormValues] = useState(formFields);
  const [formErrors, setFormErrors] = useState(
    updateNullOfObjectValues(formFields)
  );
  const [submitting, setSubmitting] = useState(false);
  const [loader, setLoader] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const [paymentAmount, setPaymentAmount] = React.useState(0);
  const [clientSecret, setClientSecret] = React.useState("");
  const [paymentDialog, setPaymentDialog] = React.useState(false);
  const [isPaymentProcessing, setIsPaymentProcessing] = React.useState(false);
  const [applicationId, setApplicationId] = React.useState(null);

  React.useEffect(() => {
    setTimeout(() => setLoader(false), 1500);
    const params = new URLSearchParams(window.location.search);
    setFormValues({
      ...formValues,
      email_id: params.get("email"),
    });
  }, []);

  const handleChange = (event) => {
    let value = event.target.value;
    if (event.target.type === "checkbox") {
      if (event.target.name === "subspecialty") {
        if (event.target.checked) {
          if (event.target.value === "None") {
            value = ["None"];
          } else {
            let subspecialty = formValues.subspecialty;
            subspecialty.push(event.target.value);
            subspecialty = subspecialty.filter((item) => item !== "None");
            value = subspecialty;
          }
        } else {
          value = formValues.subspecialty.filter(
            (item) => item !== event.target.value
          );
        }
      } else {
        if (event.target.checked) {
          value = [...formValues[event.target.name], event.target.value];
        } else {
          value = formValues[event.target.name].filter(
            (item) => item !== event.target.value
          );
        }
      }
    }
    if (event.target.name === "profession") {
      if (event.target.value != "Other") {
        formValues.profession_other = "";
      } else if (event.target.value != "Physician") {
        formValues.specialty = [];
        formValues.subspecialty_other = "";
        formValues.subspecialty = [];
        formValues.subspecialty_other = "";
      }
    } else if (event.target.name === "specialty") {
      if (!value.includes("Other")) {
        formValues.specialty_other = "";
      }
    } else if (event.target.name === "subspecialty") {
      if (!value.includes("Other")) {
        formValues.subspecialty_other = "";
      }
    } else if (event.target.name === "language") {
      if (
        !value.includes("Additional languages you speak that are not listed")
      ) {
        formValues.language_other = "";
      }
    }

    setFormValues({
      ...formValues,
      [event.target.name]: value,
    });
    setFormErrors({ ...formErrors, [event.target.name]: null });
  };

  const handleDOB = (vl) => {
    setFormValues({ ...formValues, dob: vl });
    setFormErrors({ ...formErrors, dob: null });
  };

  const handleSubmit = (plan_type, amt) => {
    setError(null);
    if (!formValidate()) {
      applicationFormRef.current.scrollIntoView();
      return;
    }

    setSubmitting(true);
    let formdata = { ...formValues, ip_address: ip_address, plan_type: plan_type, payment_amount: amt };
    if (formdata.profession == "other") {
      formdata.profession = formdata.profession_other;
    }
    if (formdata.specialty.includes("Other")) {
      formdata.specialty = formdata.specialty.filter(
        (item) => item !== "Other"
      );
      formdata.specialty.push(formdata.specialty_other);
    }
    if (formdata.subspecialty.includes("Other")) {
      formdata.subspecialty = formdata.subspecialty.filter(
        (item) => item !== "Other"
      );
      formdata.subspecialty.push(formdata.subspecialty_other);
    }
    if (
      formdata.language.includes(
        "Additional languages you speak that are not listed"
      )
    ) {
      formdata.language = formdata.language.filter(
        (item) => item !== "Additional languages you speak that are not listed"
      );
      formdata.language.push(formdata.language_other);
    }
    delete formdata.profession_other;
    delete formdata.specialty_other;
    delete formdata.subspecialty_other;
    delete formdata.language_other;
    formdata.dob = formdata.dob.format("YYYY-MM-DD");
    formdata.language = formdata.language.join(", ");
    formdata.specialty = formdata.specialty.join(", ");
    formdata.subspecialty = formdata.subspecialty.join(", ");

    onlineApplicationSubmit(formdata)
      .then((res) => {
        if (res.data.status) {
          if(amt > 0){
            setApplicationId(res.data.application_id);
            loadClientSecret(res.data.application_id, amt);
          }else{
            //show message on modal
            setSubmitting(false);
            setFormValues(formFields);
            navigate(`/payment-complete${objectToQueryString({ payment_type: 'online_application', application_id: res.data.application_id, payment_intent: generateRandomString(5), payment_intent_client_secret: generateRandomString(5) })}`);
          }
        } else {
          setError(res.data.message);
          setSubmitting(false);
        }
      })
      .catch((err) => {
        setSubmitting(false);
        setError("Something went wrong. Please try again later.");
      });
  };

  const formValidate = () => {
    let errors = {};
    let isValid = true;
    if (!formValues.membership_type) {
      errors.membership_type = "This field is required";
      isValid = false;
    }
    if (!formValues.income) {
      errors.income = "This field is required";
      isValid = false;
    }
    if (!formValues.title) {
      errors.title = "Required";
      isValid = false;
    }
    if (!formValues.first_name) {
      errors.first_name = "This field is required";
      isValid = false;
    }
    if (!formValues.last_name) {
      errors.last_name = "This field is required";
      isValid = false;
    }
    if (!formValues.email_id) {
      errors.email_id = "This field is required";
      isValid = false;
    } else if (!isEmail(formValues.email_id)) {
      errors.email_id = "Invalid email address";
      isValid = false;
    }
    if (!formValues.phone_number) {
      errors.phone_number = "This field is required";
      isValid = false;
    }
    if (!formValues.institution) {
      errors.institution = "This field is required";
      isValid = false;
    }
    if (!formValues.address) {
      errors.address = "This field is required";
      isValid = false;
    }
    if (!formValues.profession) {
      errors.profession = "This field is required";
      isValid = false;
    }
    if (formValues.profession === "Other" && !formValues.profession_other) {
      errors.profession_other = "This field is required";
      isValid = false;
    }
    if (formValues.profession === "Physician" && !formValues.specialty.length) {
      errors.specialty = "This field is required";
      isValid = false;
    }
    if (
      formValues.specialty.includes("Anesthesiology") &&
      !formValues.subspecialty.length
    ) {
      errors.subspecialty = "This field is required";
      isValid = false;
    }
    if (formValues.specialty.includes("Other") && !formValues.specialty_other) {
      errors.specialty_other = "This field is required";
      isValid = false;
    }
    if (
      formValues.subspecialty.includes("Other") &&
      !formValues.subspecialty_other
    ) {
      errors.subspecialty_other = "This field is required";
      isValid = false;
    }
    if (!formValues.race) {
      errors.race = "This field is required";
      isValid = false;
    }
    if (!formValues.ethnicity) {
      errors.ethnicity = "This field is required";
      isValid = false;
    }
    if (!formValues.gender) {
      errors.gender = "This field is required";
      isValid = false;
    }
    if (!formValues.pronouns) {
      errors.pronouns = "This field is required";
      isValid = false;
    }
    if (!formValues.sexual_orientation) {
      errors.sexual_orientation = "This field is required";
      isValid = false;
    }
    if (!formValues.dob) {
      errors.dob = "This field is required";
      isValid = false;
    }
    if (!formValues.language.length) {
      errors.language = "This field is required";
      isValid = false;
    }
    if (
      formValues.language.includes(
        "Additional languages you speak that are not listed"
      ) &&
      !formValues.language_other
    ) {
      errors.language_other = "This field is required";
      isValid = false;
    }
    setFormErrors(errors);
    return isValid;
  };

  const handlePayNow = (e, amt, plan_type) => {
    e.preventDefault();

    if (submitting) return;

    setPaymentAmount(amt);
    handleSubmit(plan_type, amt);
  };

  const loadClientSecret = (appl_id, amount) => {
    createPaymentIntent({ amount: amount, application_id: appl_id, payment_type: "online_application" })
      .then((res) => {
        if (res.data.status) {
          setClientSecret(res.data.data.client_secret);
          setPaymentDialog(true);
          setSubmitting(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePaymentDialogClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;

    if(isPaymentProcessing) return;
    setPaymentDialog(false);
  };

  const handlePaymentProcess = (d) => {
    setIsPaymentProcessing(d);
  };

  return (
    <>
      <NavbarFive />
      <div
        className="page-title-area"
        style={{ backgroundImage: `url(${aboutimg})` }}
      >
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Membership</h2>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li className="active">Online Application</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main-contact-area ptb-100">
        <div className="container  container-space">
          <div className="section-title memberlogin">
            {/*
            <h2>Online Membership Application</h2>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eaque
              quibusdam deleniti porro praesentium. Aliquam minus quisquam velit
              in at nam.
            </p> */}

            <div className="jarallax ptb-50" ref={applicationFormRef}>
              <div className="container">
                <div className="appointment-here-form membership-appli membersp-main-sec">
                  {/* <span className="top-title">Make An Appointment</span> */}
                  <h2>Membership Application</h2>

                  <form>
                    <div className="row">
                      <div className="col-lg-4 col-sm-4 width-full">
                        <label className="mem-sec-lable">
                          Membership Type <span className="redcolr">*</span>
                        </label>
                        <div className="form-group form-member">
                          <select
                            className={classNames("form-control sel-memb", {
                              "is-invalid": formErrors.membership_type,
                            })}
                            name="membership_type"
                            onChange={handleChange}
                            value={formValues.membership_type}
                          >
                            {/* <option value="0">Select Membership Type</option> */}
                            <option value="Active">
                              Active (MD, DO, MBBS, etc.)
                            </option>
                            <option value="Affiliate">
                              Affiliate (CRNA, AA, etc.)
                            </option>
                            <option value="International">International</option>
                            <option value="Trainee">
                              Trainee (Fellow, Resident, Student)
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-8 col-sm-8 member-selct deep-red width-full">
                        {/* <label>Do you live and work in a Low- or Middle-Income Country?</label> */}
                        <FormControl>
                          <FormLabel id="demo-row-radio-buttons-group-label">
                            Do you live and work in a Low or Middle-Income
                            Country?<span className="redcolr ">*</span>
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="income"
                            value={formValues.income}
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="No"
                              control={<Radio />}
                              label="No"
                              className="no-sec-mem"
                            />
                          </RadioGroup>
                        </FormControl>
                        {formErrors.income && (
                          <FormHelperText className="manual_error">
                            {formErrors.income}
                          </FormHelperText>
                        )}
                      </div>
                    </div>

                    <div className="row mem-margn-sec">
                      <div className="col-lg-4 col-sm-4 width-full">
                        <div className="row">
                          <div className="col-lg-3 col-sm-3 pading-no">
                            <label className="mem-sec-lable">
                              Title<span className="redcolr">*</span>
                            </label>
                            <div className="form-group form-member">
                              <select
                                className={classNames("form-control sel-memb", {
                                  "is-invalid": formErrors.title,
                                })}
                                name="title"
                                onChange={handleChange}
                                value={formValues.title}
                              >
                                {/* <option value="0">Select Membership Type</option> */}
                                <option value="Dr.">Dr.</option>
                                <option value="Mr.">Mr.</option>
                                <option value="Mrs.">Mrs.</option>
                                <option value="Ms.">Ms.</option>
                              </select>
                              {formErrors.title && (
                                <div className="invalid-feedback">
                                  {formErrors.title}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-9 col-sm-9">
                            <label className="mem-sec-lable">
                              First Name<span className="redcolr">*</span>
                            </label>
                            <div className="form-group">
                              <input
                                type="text"
                                className={classNames("form-control", {
                                  "is-invalid": formErrors.first_name,
                                })}
                                id="firstname"
                                placeholder="Enter Your First Name"
                                name="first_name"
                                onChange={handleChange}
                                value={formValues.first_name}
                              />
                              {formErrors.first_name && (
                                <div className="invalid-feedback">
                                  {formErrors.first_name}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-4 width-full mid-sec-min">
                        <label className="mem-sec-lable">Middle Name</label>
                        <div className="form-group">
                          <input
                            type="text"
                            className={classNames("form-control", {
                              "is-invalid": formErrors.middle_name,
                            })}
                            id="firstname"
                            placeholder="Enter Your Middle Name"
                            name="middle_name"
                            onChange={handleChange}
                            value={formValues.middle_name}
                          />
                          {formErrors.middle_name && (
                            <div className="invalid-feedback">
                              {formErrors.middle_name}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-4 width-full">
                        <label className="mem-sec-lable">
                          Last Name<span className="redcolr">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            type="text"
                            className={classNames("form-control", {
                              "is-invalid": formErrors.last_name,
                            })}
                            id="firstname"
                            placeholder="Enter Your Last Name"
                            name="last_name"
                            onChange={handleChange}
                            value={formValues.last_name}
                          />
                          {formErrors.last_name && (
                            <div className="invalid-feedback">
                              {formErrors.last_name}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row mem-margn-sec">
                      <div className="col-lg-4 col-sm-4 width-full">
                        <label className="mem-sec-lable">
                          Email Id <span className="redcolr">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            type="text"
                            className={classNames("form-control", {
                              "is-invalid": formErrors.email_id,
                            })}
                            id="Emailid"
                            placeholder="Enter Your Email Id"
                            name="email_id"
                            onChange={handleChange}
                            value={formValues.email_id}
                          />
                          {formErrors.email_id && (
                            <div className="invalid-feedback">
                              {formErrors.email_id}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-4 col-sm-4 width-full">
                        <label className="mem-sec-lable">
                          Institution <span className="redcolr">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            type="text"
                            className={classNames("form-control", {
                              "is-invalid": formErrors.institution,
                            })}
                            id="institution"
                            placeholder="Enter Your Institution"
                            name="institution"
                            onChange={handleChange}
                            value={formValues.institution}
                          />
                          {formErrors.institution && (
                            <div className="invalid-feedback">
                              {formErrors.institution}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-4 col-sm-4 width-full">
                        <label className="mem-sec-lable">
                          Address <span className="redcolr">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            type="text"
                            className={classNames("form-control", {
                              "is-invalid": formErrors.address,
                            })}
                            id="address"
                            placeholder="Enter Your Address"
                            name="address"
                            onChange={handleChange}
                            value={formValues.address}
                          />
                          {formErrors.address && (
                            <div className="invalid-feedback">
                              {formErrors.address}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row mem-margn-sec ">
                      <div className="col-lg-4 col-sm-4 width-full">
                        <label className="mem-sec-lable">
                          Phone Number <span className="redcolr">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            type="text"
                            className={classNames("form-control", {
                              "is-invalid": formErrors.phone_number,
                            })}
                            id="phone_number"
                            placeholder="Enter Your Phone"
                            name="phone_number"
                            onChange={handleChange}
                            value={formValues.phone_number}
                          />
                          {formErrors.phone_number && (
                            <div className="invalid-feedback">
                              {formErrors.phone_number}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-4 col-sm-4 width-full">
                        <label className="mem-sec-lable">
                          Profession<span className="redcolr">*</span>
                        </label>
                        <div className="form-group form-member">
                          <select
                            className={classNames("form-control sel-memb", {
                              "is-invalid": formErrors.profession,
                            })}
                            name="profession"
                            onChange={handleChange}
                            value={formValues.profession}
                          >
                            <option value="">Select Profession</option>
                            <option value="Physician">Physician</option>
                            <option value="CRNA">CRNA</option>
                            <option value="AA">AA</option>
                            <option value="Trainee">Trainee</option>
                            <option value="Other">Other</option>
                          </select>
                          {formErrors.profession && (
                            <div className="invalid-feedback">
                              {formErrors.profession}
                            </div>
                          )}
                        </div>
                      </div>

                      {formValues.profession === "Other" && (
                        <div className="col-lg-4 col-sm-4">
                          <label className="mem-sec-lable">
                            Other<span className="redcolr">*</span>
                          </label>
                          <div className="form-group">
                            <input
                              type="text"
                              className={classNames("form-control", {
                                "is-invalid": formErrors.profession_other,
                              })}
                              id="other"
                              placeholder="Enter Your other"
                              name="profession_other"
                              onChange={handleChange}
                              value={formValues.profession_other}
                            />
                            {formErrors.profession_other && (
                              <div className="invalid-feedback">
                                {formErrors.profession_other}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>

                    {formValues.profession === "Physician" && (
                      <div className="row mem-margn-sec">
                        <div className="col-md-12">
                          <h2 className="ch-spacl">
                            Choose Specialty<span className="redcolr">*</span>
                          </h2>
                          <FormGroup
                            className="checksec-form"
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="Anesthesiology"
                                  name="specialty"
                                  checked={formValues.specialty.includes(
                                    "Anesthesiology"
                                  )}
                                />
                              }
                              label="Anesthesiology"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="Obstetrics"
                                  name="specialty"
                                  checked={formValues.specialty.includes(
                                    "Obstetrics"
                                  )}
                                />
                              }
                              label="Obstetrics"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="Pediatrics"
                                  name="specialty"
                                  checked={formValues.specialty.includes(
                                    "Pediatrics"
                                  )}
                                />
                              }
                              label="Pediatrics"
                            />

                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="Surgery"
                                  name="specialty"
                                  checked={formValues.specialty.includes(
                                    "Surgery"
                                  )}
                                />
                              }
                              label="Surgery"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="Neonatology"
                                  name="specialty"
                                  checked={formValues.specialty.includes(
                                    "Neonatology"
                                  )}
                                />
                              }
                              label="Neonatology"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="Radiology"
                                  name="specialty"
                                  checked={formValues.specialty.includes(
                                    "Radiology"
                                  )}
                                />
                              }
                              label="Radiology"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="Cardiology"
                                  name="specialty"
                                  checked={formValues.specialty.includes(
                                    "Cardiology"
                                  )}
                                />
                              }
                              label="Cardiology"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="Other"
                                  name="specialty"
                                  checked={formValues.specialty.includes(
                                    "Other"
                                  )}
                                />
                              }
                              label="Other"
                            />
                          </FormGroup>
                        </div>
                        <div className="manul-secss">
                          {formErrors.specialty && (
                            <FormHelperText className="manual_error">
                              {formErrors.specialty}
                            </FormHelperText>
                          )}
                        </div>
                        {formValues.specialty.includes("Other") && (
                          <div className="col-lg-4 col-sm-4">
                            <label className="mem-sec-lable">
                              Other<span className="redcolr">*</span>
                            </label>
                            <div className="form-group">
                              <input
                                type="text"
                                className={classNames("form-control", {
                                  "is-invalid": formErrors.specialty_other,
                                })}
                                id="specialty_other"
                                placeholder="Enter Your other"
                                name="specialty_other"
                                onChange={handleChange}
                                value={formValues.specialty_other}
                              />
                              {formErrors.specialty_other && (
                                <div className="invalid-feedback">
                                  {formErrors.specialty_other}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {formValues.specialty.includes("Anesthesiology") && (
                      <div className="row mem-margn-sec">
                        <div className="col-md-12">
                          <h2 className="ch-spacl">
                            Choose Subspecialty
                            <span className="redcolr">*</span>
                          </h2>
                          <FormGroup
                            className="checksec-form"
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="Pediatric Anesthesia"
                                  name="subspecialty"
                                  checked={formValues.subspecialty.includes(
                                    "Pediatric Anesthesia"
                                  )}
                                />
                              }
                              label="Pediatric Anesthesia"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="Obstetric Anesthesia"
                                  name="subspecialty"
                                  checked={formValues.subspecialty.includes(
                                    "Obstetric Anesthesia"
                                  )}
                                />
                              }
                              label="Obstetric Anesthesia"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="Other"
                                  name="subspecialty"
                                  checked={formValues.subspecialty.includes(
                                    "Other"
                                  )}
                                />
                              }
                              label="Other"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="None"
                                  name="subspecialty"
                                  checked={formValues.subspecialty.includes(
                                    "None"
                                  )}
                                />
                              }
                              label="None"
                            />
                          </FormGroup>
                        </div>
                        {formErrors.subspecialty && (
                          <div className="manul-secss">
                            <FormHelperText className="manual_error ">
                              {formErrors.subspecialty}
                            </FormHelperText>
                          </div>
                        )}
                        {formValues.subspecialty.includes("Other") && (
                          <div className="col-lg-4 col-sm-4">
                            <label className="mem-sec-lable">
                              Other<span className="redcolr">*</span>
                            </label>
                            <div className="form-group">
                              <input
                                type="text"
                                className={classNames("form-control", {
                                  "is-invalid": formErrors.subspecialty_other,
                                })}
                                id="subspecialty_other"
                                placeholder="Enter Your other"
                                name="subspecialty_other"
                                onChange={handleChange}
                                value={formValues.subspecialty_other}
                              />
                              {formErrors.subspecialty_other && (
                                <div className="invalid-feedback">
                                  {formErrors.subspecialty_other}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    <div className="demo-grph">
                      <div className="row">
                        <div className="col-md-12">
                          <h2>Demographic Info</h2>
                          <p>
                            To help us accurately assess the diversity of the
                            society, please complete the following demographic
                            information. Confidential demographic data will be
                            used in the aggregate and only for authorized
                            purposes. Your participation is voluntary but would
                            be appreciated; use &quot;prefer not to answer&quot;
                            to decline. Please select all options that apply.
                          </p>
                          <p>
                            Why are we asking? By knowing more about the
                            diversity of our membership, SMFA can more
                            effectively develop resources and programming to
                            serve member needs, which in turn will benefit the
                            patient population we serve.
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-4 col-sm-4 width-full">
                          <label className="mem-sec-lable">
                            Race<span className="redcolr">*</span>
                          </label>
                          <div className="form-group form-member">
                            <select
                              className={classNames("form-control sel-memb", {
                                "is-invalid": formErrors.race,
                              })}
                              name="race"
                              onChange={handleChange}
                              value={formValues.race}
                            >
                              <option value="">Select Race</option>
                              <option value="Asian">
                                Asian (including Chinese, Indian, Japanese,
                                Korean, Pakistani, any other Asian Background)
                              </option>
                              <option value="Black">
                                Black (African, Caribbean, any other
                                Black/African/Caribbean background)
                              </option>
                              <option value="White">
                                White (including Eastern/Northern European and
                                any other White background)
                              </option>
                              <option value="Native American/Alaska Native/Indigenous Person ">
                                Native American/Alaska Native/Indigenous Person
                              </option>
                              <option value="Native Hawaiian or Other Pacific Islander">
                                Native Hawaiian or Other Pacific Islander
                              </option>
                              <option value="Middle Eastern or North African">
                                Middle Eastern or North African
                              </option>
                              <option value="Race Not Listed">
                                Race Not Listed
                              </option>
                              <option value="Prefer Not to Answer">
                                Prefer Not to Answer
                              </option>
                            </select>
                            {formErrors.race && (
                              <div className="invalid-feedback">
                                {formErrors.race}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-4 col-sm-4 width-full">
                          <label className="mem-sec-lable">
                            Ethnicity<span className="redcolr">*</span>
                          </label>
                          <div className="form-group form-member">
                            <select
                              className={classNames("form-control sel-memb", {
                                "is-invalid": formErrors.ethnicity,
                              })}
                              name="ethnicity"
                              onChange={handleChange}
                              value={formValues.ethnicity}
                            >
                              <option value="">Select Ethnicity</option>
                              <option value="Latina/Latino or Hispanic">
                                Latina/Latino or Hispanic
                              </option>
                              <option value="Not Latina/Latino or Hispanic">
                                Not Latina/Latino or Hispanic
                              </option>
                              <option value="Prefer Not to Answer">
                                Prefer Not to Answer
                              </option>
                            </select>
                            {formErrors.ethnicity && (
                              <div className="invalid-feedback">
                                {formErrors.ethnicity}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-4 col-sm-4 width-full">
                          <label className="mem-sec-lable">
                            Gender Identity <span className="redcolr">*</span>
                          </label>
                          <div className="form-group form-member">
                            <select
                              className={classNames("form-control sel-memb", {
                                "is-invalid": formErrors.gender,
                              })}
                              name="gender"
                              onChange={handleChange}
                              value={formValues.gender}
                            >
                              <option value="">Select Gender</option>
                              <option value="Female">Female</option>
                              <option value="Male">Male</option>
                              <option value="Nonbinary/Gender Fluid">
                                Nonbinary/Gender Fluid
                              </option>
                              <option value="Transgender Male">
                                Transgender Male
                              </option>
                              <option value="Transgender Female">
                                Transgender Female
                              </option>
                              <option value="Gender Not Listed">
                                Gender Not Listed
                              </option>
                              <option value="Prefer Not to Answer">
                                Prefer Not to Answer
                              </option>
                            </select>
                            {formErrors.gender && (
                              <div className="invalid-feedback">
                                {formErrors.gender}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-4 col-sm-4 width-full">
                          <label className="mem-sec-lable">
                            Pronouns<span className="redcolr">*</span>
                          </label>
                          <div className="form-group form-member">
                            <select
                              className={classNames("form-control sel-memb", {
                                "is-invalid": formErrors.pronouns,
                              })}
                              name="pronouns"
                              onChange={handleChange}
                              value={formValues.pronouns}
                            >
                              <option value="">Select Pronouns</option>
                              <option value="He/Him/His">He/Him/His</option>
                              <option value="She/Her/Hers">She/Her/Hers</option>
                              <option value="They/Them/Their">
                                They/Them/Their
                              </option>
                              <option value="Choice Not Listed">
                                Choice Not Listed
                              </option>
                              <option value="Prefer Not to Answer">
                                Prefer Not to Answer
                              </option>
                            </select>
                            {formErrors.pronouns && (
                              <div className="invalid-feedback">
                                {formErrors.pronouns}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-4 col-sm-4 width-full">
                          <label className="mem-sec-lable">
                            Sexual Orientation<span className="redcolr">*</span>
                          </label>
                          <div className="form-group form-member">
                            <select
                              className={classNames("form-control sel-memb", {
                                "is-invalid": formErrors.sexual_orientation,
                              })}
                              name="sexual_orientation"
                              onChange={handleChange}
                              value={formValues.sexual_orientation}
                            >
                              <option value="">
                                Select Sexual Orientation
                              </option>
                              <option value="Heterosexual">Heterosexual</option>
                              <option value="LGBTQ+">LGBTQ+</option>
                              <option value="Sexual Orientation Not Listed">
                                Sexual Orientation Not Listed
                              </option>
                              <option value="Prefer Not to Answer">
                                Prefer Not to Answer
                              </option>
                            </select>
                            {formErrors.sexual_orientation && (
                              <div className="invalid-feedback">
                                {formErrors.sexual_orientation}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-4 col-sm-4 mem-date-sec requireds width-full">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["MobileDatePicker"]}>
                              <DemoItem label="Date of Birth">
                                <MobileDatePicker
                                  value={dayjs(formValues.dob)}
                                  onChange={handleDOB}
                                  slotProps={{
                                    textField: {
                                      color: formErrors.dob
                                        ? "error"
                                        : "primary",
                                      error: !!formErrors.dob,
                                      helperText: formErrors.dob,
                                    },
                                  }}
                                />
                              </DemoItem>
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <h2 className="ch-spacl">
                            Choose Languages <span className="redcolr">*</span>
                          </h2>
                          <FormGroup
                            className="checksec-form checksmf-lang"
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="Arabic"
                                  name="language"
                                  checked={formValues.language.includes(
                                    "Arabic"
                                  )}
                                />
                              }
                              label="Arabic"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="English"
                                  name="language"
                                  checked={formValues.language.includes(
                                    "English"
                                  )}
                                />
                              }
                              label="English"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="French"
                                  name="language"
                                  checked={formValues.language.includes(
                                    "French"
                                  )}
                                />
                              }
                              label="French"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="German"
                                  name="language"
                                  checked={formValues.language.includes(
                                    "German"
                                  )}
                                />
                              }
                              label="German"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="Hebrew"
                                  name="language"
                                  checked={formValues.language.includes(
                                    "Hebrew"
                                  )}
                                />
                              }
                              label="Hebrew"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="Japanese"
                                  name="language"
                                  checked={formValues.language.includes(
                                    "Japanese"
                                  )}
                                />
                              }
                              label="Japanese"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="Korean"
                                  name="language"
                                  checked={formValues.language.includes(
                                    "Korean"
                                  )}
                                />
                              }
                              label="Korean"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="Mandarin"
                                  name="language"
                                  checked={formValues.language.includes(
                                    "Mandarin"
                                  )}
                                />
                              }
                              label="Mandarin"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="Portuguese"
                                  name="language"
                                  checked={formValues.language.includes(
                                    "Portuguese"
                                  )}
                                />
                              }
                              label="Portuguese"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="Spanish"
                                  name="language"
                                  checked={formValues.language.includes(
                                    "Spanish"
                                  )}
                                />
                              }
                              label="Spanish"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="Language Not Listed"
                                  name="language"
                                  checked={formValues.language.includes(
                                    "Language Not Listed"
                                  )}
                                />
                              }
                              label="Language Not Listed"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="Prefer Not to Answer"
                                  name="language"
                                  checked={formValues.language.includes(
                                    "Prefer Not to Answer"
                                  )}
                                />
                              }
                              label="Prefer Not to Answer"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="Additional languages you speak that are not listed"
                                  name="language"
                                  checked={formValues.language.includes(
                                    "Additional languages you speak that are not listed"
                                  )}
                                />
                              }
                              label="Additional languages you speak that are not listed"
                            />
                          </FormGroup>
                        </div>
                        {formErrors.language && (
                          <div>
                            <FormHelperText className="manual_error manual_errorss">
                              {formErrors.language}
                            </FormHelperText>
                          </div>
                        )}
                      </div>
                      {formValues.language.includes(
                        "Additional languages you speak that are not listed"
                      ) && (
                        <div className="row">
                          <div className="col-lg-4 col-sm-4">
                            <label className="mem-sec-lable">
                              Other<span className="redcolr">*</span>
                            </label>
                            <div className="form-group">
                              <input
                                type="text"
                                className={classNames("form-control", {
                                  "is-invalid": formErrors.language_other,
                                })}
                                id="language_other"
                                placeholder="Enter Your other"
                                name="language_other"
                                onChange={handleChange}
                                value={formValues.language_other}
                              />
                              {formErrors.language_other && (
                                <div className="invalid-feedback">
                                  {formErrors.language_other}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {/*<div className="row">
                      <div className="col-12">
                        <button type="submit" className="default-btn membr-btn">
                          Submit{" "}
                          {submitting && (
                            <div
                              className="spinner-border spinner-border-sm"
                              role="status"
                            >
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          )}
                        </button>
                      </div>
                    </div>*/}
                  </form>
                  <div className="validate-sec">
                    {success && (
                      <div className="">
                        <p className="succ-msg" style={{ top: "20px" }}>
                          {success}
                        </p>
                      </div>
                    )}
                    {error && (
                      <div className="">
                        <p className="error-msg" style={{ top: "20px" }}>
                          {error}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* plan section start */}
        <div className="container-fluid">
          <div className="price-sec member-price-sec mem-prc-sec">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <h2>Membership Plans </h2>
              </div>
            </div>
            <div className="price-main-sec">
              <div className="row">
                <div className="col-lg-3 col-sm-3 price-mains actvn-min">
                  <div className="price-main-secc memm-price-min-sec">
                    <div className="price-sec-tab price-sec-tabss">
                      <h4>Active Members</h4>

                      <p>$200</p>
                    </div>

                    <div className="price-tab-sec pric-first-tab">
                      {/*<p>
                        This includes physician anesthesiologists. The annual
                        membership dues are USD $200.
                      </p>*/}
                    </div>
                  </div>
                  <Link
                    to="#"
                    onClick={(e) => handlePayNow(e, 200, "active_members")}
                    className="a-sec"
                  >
                    <button className="pay-btn pay-pos mem-affli-pay">Pay Now</button>
                  </Link>
                </div>

                <div className="col-lg-3 col-sm-3 price-mains price-mains-non-mem">
                  <div className="price-main-secc">
                    <div className="price-sec-tab price-sec-tabss price-non-mem">
                      <h4>Affiliate Members</h4>

                      <p>$200 </p>
                    </div>

                    <div className="price-tab-sec pric-sec-tab">
                      {/*<p>
                        This includes non-physician advanced practice providers.
                        The annual membership dues are USD $200.
                      </p>*/}
                    </div>
                  </div>
                  <Link
                    to="#"
                    onClick={(e) => handlePayNow(e, 200, "affiliate_members")}
                    className="a-sec"
                  >
                    <button className="pay-btn pay-btn-non mem-afflis-pay">Pay Now</button>
                  </Link>
                </div>

                <div className="col-lg-3 col-sm-3 price-mains price-mains-stud">
                  <div className="price-main-secc">
                    <div className="price-sec-tab price-sec-tabss price-non-stud">
                      <h4>Active Members, Reduced Cost</h4>

                      <p>$50</p>
                    </div>

                    <div className="price-tab-sec ">
                      {/*<p>
                        This includes physician and non-physician anesthesia
                        providers practicing in low-, lower middle-, and upper
                        middle-income countries as defined by the World Bank.
                        The annual membership dues are USD $50.
                      </p>*/}
                    </div>
                  </div>
                  <Link
                    to="#"
                    onClick={(e) =>
                      handlePayNow(e, 50, "active_members_reduce_cost")
                    }
                    className="a-sec"
                  >
                    {" "}
                    <button className="pay-btn pay-btn-std mem-actv-pay">Pay Now</button>
                  </Link>
                </div>

                <div className="col-lg-3 col-sm-3 price-mains price-mains-actv price-trn-actv">
                  <div className="price-main-secc">
                    <div className="price-sec-tab price-sec-tabss price-actv">
                      <h4>Trainee Members</h4>

                      <p>$0</p>
                    </div>

                    <div className="price-tab-sec">
                      {/*<p>
                        This includes medical students, residents, and fellows
                        interested in the field of anesthesia for maternal-fetal
                        interventions. Membership is complimentary.
                      </p>*/}
                    </div>
                  </div>
                  <Link
                    to="#"
                    onClick={(e) => handlePayNow(e, 0, "trainee_members")}
                    className="a-sec"
                  >
                    <button className="pay-btn pay-btn-atv mem-train-pay">Pay Now</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* plan section end */}

        <Dialog
          fullWidth
          maxWidth={"sm"}
          open={paymentDialog}
          onClose={handlePaymentDialogClose}  className="strip-main-sec"
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
          ></DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handlePaymentDialogClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            className="strip-pay-sec"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent >
            {clientSecret && (
              <Elements
                stripe={stripe}
                options={{
                  clientSecret,
                  theme: "stripe",
                }}
              >
                <PaymentForm
                  amount={paymentAmount}
                  clientSecret={clientSecret}
                  onPaymentProcess={handlePaymentProcess}
                  successParams={{ payment_type: 'online_application', application_id: applicationId }}
                />
              </Elements>
            )}
          </DialogContent>
        </Dialog>
        {submitting && <PageContentLoader />}

      </div>

      {loader ? <Preloader /> : null}
      <Footer />
    </>
  );
};
export default Onlineapplication;
