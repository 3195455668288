import React from "react";
import ComingSoon from "./Common/ComingSoon";
import Preloader from "./Common/Preloader";

const MembershipLogin = () => {
  const [loader, setLoader] = React.useState(true);
  React.useEffect(() => {
    setTimeout(() => setLoader(false), 1500);
  }, []);
  return (
    <>
      <ComingSoon />
      {loader ? <Preloader /> : null}
    </>
  );
};

export default MembershipLogin;
