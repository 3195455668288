import React from "react";
import {
  Link,
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import blogone from '../../styles/img/blog1.jpg';
import blogtwo from '../../styles/img/blog2.jpg';
import blogthree from '../../styles/img/blog3.jpg';
import { useSelector } from "react-redux";
import classNames from "classnames";

const NewsStyleOne = () => {
  const { homeData } = useSelector((state) => state.pageContents);

  return (
    <>
      {
        (homeData?.homenews || homeData?.newsdata.length) &&
        <div className="blog-area pt-100 pb-70">
          <div className="container">
            {
              homeData?.homenews &&
              <div className="section-title">
                {/* <span className="top-title">News</span> */}
                <h2>{homeData.homenews.news_title}</h2>
                <p>
                  {homeData.homenews.news_description}
                </p>
              </div>
            }
            {
              homeData?.newsdata.length &&
              <div className="row justify-content-center">
                {
                  homeData.newsdata.map((item, index) => (
                    <div className="col-lg-4 col-md-6" key={index}>
                      <div className="single-blog">
                        <Link href="#">
                          <img src={item.news_image} alt="Image" />
                          <div className="blog-illi">
                            {/* <p className="illi-secsnews">Illustration Only</p> */}
                          </div>
                        </Link>

                        <span>{item.news_date_display}</span>
                        <div className={classNames("blog-content", {
                          'blog-middle': index === 1
                        })}>
                          <ul>
                            <li>
                              <Link href="#">{item.news_label}</Link>
                            </li>
                          </ul>

                          <Link href="#">
                            <h3>{item.news_title}</h3>
                          </Link>

                          <Link href="/" className="read-more">
                            Read More <i className="bx bx-plus"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))
                }

              </div>
            }
          </div>
        </div>
      }
    </>
  );
};

export default NewsStyleOne;
