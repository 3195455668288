import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

const PaginationButton = ({
    className,
    label,
    onClick,
    page,
}) => (
    <a
        href="#"
        className={`${className}`}
        onClick={(e) => onClick(e, page)}
    >
        {label || page}
    </a>
);

const Pagination = ({
    classes,
    initialPage = 1,
    itemsPerPage = 10,
    maxVisibleButtons = 5,
    className='',
    onChange,
    totalItems,
}) => {
    const [pager, setPager] = useState({
        currentPage: initialPage,
        totalPages: Math.ceil(totalItems / itemsPerPage),
    });

    useEffect(() => {
        setPager((prevPager) => ({
            ...prevPager,
            currentPage: initialPage,
            totalPages: Math.ceil(totalItems / itemsPerPage),
        }));
    }, [initialPage, itemsPerPage, totalItems]);

    const setPage = (e, page) => {
        e.preventDefault();
        if (page < 1 || page > pager.totalPages) {
            return;
        }

        setPager((prevPager) => ({
            ...prevPager,
            currentPage: page,
        }));

        onChange(page);
    };

    const { currentPage, totalPages } = pager;

    if (totalPages <= 1) {
        return null;
    }

    const startPage = Math.max(1, currentPage - Math.floor(maxVisibleButtons / 2));
    let endPage = Math.min(totalPages, startPage + maxVisibleButtons - 1);

    if (endPage - startPage + 1 < maxVisibleButtons) {
        endPage = Math.min(totalPages, startPage + maxVisibleButtons - 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
    }

    const isFirstOrLast = (number) => number !== 1 && number !== totalPages;
    const hasOverflow = totalPages > maxVisibleButtons;
    const isFirstPageEllipsed = startPage !== 1 && hasOverflow;
    const isLastPageEllipsed = endPage !== totalPages && hasOverflow;

    return (
        <>
            <div className={`page-navigation-area ${className?className:''}`}>
                <nav>
                    <ul className="pagination">
                        <li className="page-item">
                            <a className="page-link page-links" href="#" disabled={currentPage === 1} onClick={(e) => setPage(e, currentPage - 1)}>
                                <i className="bx bx-chevrons-left"></i>
                            </a>
                        </li>
                        <li className={classNames("page-item", {
                            "active": currentPage === 1
                        })}>
                            <PaginationButton
                                className="page-link page-links"
                                page={1}
                                onClick={setPage}
                            />
                        </li>
                        {isFirstPageEllipsed && <li className="page-item">...</li>}

                        {pages.filter(isFirstOrLast).map((page) => (
                            <li className={classNames("page-item", {
                                "active": currentPage === page
                            })} key={`pagination-item-${page}`}>
                                <PaginationButton
                                    className="page-link page-links"
                                    page={page}
                                    onClick={setPage}
                                />
                            </li>
                        ))}

                        {isLastPageEllipsed && <li className="page-item">...</li>}
                        {totalPages > 1 && (
                            <li className={classNames("page-item", {
                                "active": currentPage === totalPages
                            })}>
                                <PaginationButton
                                    className="page-link page-links"
                                    page={totalPages}
                                    onClick={setPage}
                                />
                            </li>
                        )}
                        <li className="page-item">
                            <a className="page-link page-links" href="#" disabled={currentPage === totalPages} onClick={(e) => setPage(e, currentPage + 1)}>
                                <i className="bx bx-chevrons-right"></i>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    );
};

export default Pagination;