import React, { useEffect, useState } from "react";
import NavbarFive from "./Common/NavbarFive";
import Footer from "./Common/Footer";
import { useSearchParams, useNavigate } from "react-router-dom";
import Preloader from "./Common/Preloader";
import { checkPaymentStatus } from "../actions/payment.actions";
import { useDispatch } from "react-redux";
import { generateUniqueToken } from "./../utils/helper";
import { setFormToken } from "./../features/userdataSlice";
import Pusher from "pusher-js";
import CircularProgress from "@mui/material/CircularProgress";

const PaymentComplete = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loader, setLoader] = React.useState(true);
  const [processing, setProcessing] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const dispatch = useDispatch();
  const [updatedBy, setUpdatedBy] = useState("");
  const [paymentData, setPaymentData] = useState(null);
  const [paymentType, setPaymentType] = useState("");

  useEffect(() => {
    const payment_type = searchParams.get("payment_type");
    const registration_token = searchParams.get("registration_token");
    const application_id = searchParams.get("application_id");
    const payment_intent = searchParams.get("payment_intent");
    const payment_intent_client_secret = searchParams.get(
      "payment_intent_client_secret"
    );
    if (
      ((payment_type == "register_member" && registration_token) ||
        (payment_type == "online_application" && application_id)) &&
      payment_intent &&
      payment_intent_client_secret
    ) {
      setPaymentType(payment_type);
      checkPaymentStats(
        payment_intent,
        payment_intent_client_secret,
        payment_type,
        registration_token,
        application_id
      );
    } else {
      navigate("/");
    }
    setTimeout(() => setLoader(false), 1500);
  }, []);

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      //encrypted: true,
    });
    //Pusher.logToConsole = true;
    const channel = pusher.subscribe("smfa_channel");

    channel.bind("smfa_payment", (d) => {
      if (d.data.reg_token == searchParams.get("registration_token")) {
        setUpdatedBy("pusher");
        setPaymentStatus(d.data.status);
        setPaymentData(d.data);
      }
    });

    return () => {
      channel.unbind_all();
      pusher.unsubscribe("smfa_channel");
    };
  }, []);

  const checkPaymentStats = (
    payment_intent,
    payment_intent_client_secret,
    payment_type,
    registration_token,
    application_id
  ) => {
    setProcessing(true);
    checkPaymentStatus({
      registration_token,
      payment_type,
      application_id,
      payment_intent,
      payment_intent_client_secret,
    })
      .then((res) => {
        if (updatedBy != "pusher") {
          setPaymentStatus(res.data.data.status);
          setPaymentData(res.data.data);
        }
        const token = generateUniqueToken();
        dispatch(setFormToken(token));
        setProcessing(false);
      })
      .catch((error) => {
        setErrorMsg(
          "An error occurred while processing your payment. Please try again later."
        );
        setProcessing(false);
      });
  };

  return (
    <>
      <>
        <NavbarFive />

        <div className="payment-complete-container">
          <div className="container">
            <div className="payment-complete">
              {processing ? (
                <div className="paye-process">
                  <CircularProgress />
                  <p>
                    Your payment is being processed, please wait while we check
                  </p>
                </div>
              ) : (
                <>
                  {paymentStatus == "succeeded" ? (
                    <div className="paye-process">
                      <div className="payme-sucess">
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 130.2 130.2"
                        >
                          <circle
                            class="path circle"
                            fill="none"
                            stroke="#73AF55"
                            stroke-width="6"
                            stroke-miterlimit="10"
                            cx="65.1"
                            cy="65.1"
                            r="62.1"
                          />
                          <polyline
                            class="path check"
                            fill="none"
                            stroke="#73AF55"
                            stroke-width="6"
                            stroke-linecap="round"
                            stroke-miterlimit="10"
                            points="100.2,40.2 51.5,88.8 29.8,67.5 "
                          />
                        </svg>
                      </div>
                      {paymentData.payment_type == "register_member" ? (
                        <>
                          <p className="congrads-firs-sec">
                            Congratulations !!
                          </p>
                          <p className="congrads-mid-sec">
                            We have received your payment {paymentData.amount_display} for registering you as a {paymentData.plan}.
                          </p>
                          <p className="congrads-end-sec">
                            SMFA team will be in touch with you soon.
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="congrads-firs-sec">
                            Congratulations !!
                          </p>
                          <p className="congrads-mid-sec">
                            {paymentData.amount > 0 ? (
                              <>
                                We have received your payment{" "}
                                {paymentData.amount_display} for Membership
                                application as {paymentData.plan}.
                              </>
                            ) : (
                              <>
                                We are in receipt of your membership application
                                as {paymentData.plan}.
                              </>
                            )}
                          </p>
                          <p className="congrads-end-sec">
                            SMFA team will be in touch with you soon.
                          </p>
                        </>
                      )}
                    </div>
                  ) : (
                    <>
                      {paymentStatus == "failed" ? (
                        <div className="paye-process">
                          <div className="payme-sucess fail-sec">
                            <svg
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 130.2 130.2"
                            >
                              <circle
                                class="path circle"
                                fill="none"
                                stroke="#FF0000"
                                stroke-width="6"
                                stroke-miterlimit="10"
                                cx="65.1"
                                cy="65.1"
                                r="62.1"
                              />
                              <line
                                class="path line"
                                fill="none"
                                stroke="#FF0000"
                                stroke-width="6"
                                stroke-linecap="round"
                                stroke-miterlimit="10"
                                x1="34.4"
                                y1="37.9"
                                x2="95.8"
                                y2="92.3"
                              />
                              <line
                                class="path line"
                                fill="none"
                                stroke="#FF0000"
                                stroke-width="6"
                                stroke-linecap="round"
                                stroke-miterlimit="10"
                                x1="95.8"
                                y1="38"
                                x2="34.4"
                                y2="92.2"
                              />
                            </svg>
                          </div>
                          <p
                            className="congrads-firs-sec"
                            style={{ marginBottom: "13px" }}
                          >
                            Oops !!
                          </p>
                          <p className="congrads-mid-sec">
                            Did we miss something? It seems your payment has
                            failed &#128546;.
                          </p>

                          <p
                            class="congrads-mid-sec"
                            style={{ marginTop: "5px" }}
                          >
                            Please try again or use a different payment method.
                          </p>
                        </div>
                      ) : (
                        <div className="paye-process">
                          <CircularProgress />
                          <p>
                            Your payment is being processed, please wait while
                            we check
                          </p>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </>
      {loader ? <Preloader /> : null}
    </>
  );
};

export default PaymentComplete;
