import React from 'react';
import NavbarFive from "./Common/NavbarFive";
import PageBanner from "../components/Common/PageBanner";
import faq_img from '../styles/img/faq.png';
import Footer from "../components/Common/Footer";
import Preloader from "./Common/Preloader";
import { Link } from "react-router-dom";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from "react-accessible-accordion";
import aboutimg from '../styles/img/appointment-bg4.jpg';
import { useSelector } from "react-redux";
import { isComingSoon } from "../utils/helper";
import ComingSoon from "./Common/ComingSoon";


const Patients = () => {
    const [loader, setLoader] = React.useState(true);
    const { patientsData, homeData } = useSelector((state) => state.pageContents);
    React.useEffect(() => {
        setTimeout(() => setLoader(false), 1500);
    }, []);
    return (
        <>
            {
                homeData?.comingsoon && isComingSoon(homeData.comingsoon, 'Patients') ?
                    <ComingSoon />
                    :
                    <>
                        <NavbarFive />



                        {
                            patientsData?.patientbanner &&
                            <>

                                <div className="page-title-area" style={{ backgroundImage: `url('${patientsData.patientbanner.banner_image}')` }}>
                                    <div className="d-table">
                                        <div className="d-table-cell">
                                            <div className="container">
                                                <div className="page-title-content">
                                                    <h2>{patientsData.patientbanner.banner_title}</h2>
                                                    <ul>
                                                        <li><a href="/">Home</a></li>
                                                        <li className="active">{patientsData.patientbanner.banner_title}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        <div className="faq-area bg-2 ptb-100">
                            <div className="container">
                                {
                                    patientsData?.patientorysection &&
                                    <>

                                        <div className="section-title section-title-faq">

                                            <h2>{patientsData.patientorysection.section_title}</h2>
                                            <p>
                                                {patientsData.patientorysection.section_description}
                                            </p>
                                        </div>
                                    </>
                                }

                                <div className="row align-items-center">

                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-8">
                                        <div className="faq-accordion">
                                            <Accordion allowZeroExpanded preExpanded={[0]}>



                                                {patientsData.patientqueAnswer.length > 0 ? <>



                                                    <>
                                                        {patientsData.patientqueAnswer.map((item, index) => (

                                                            <AccordionItem uuid={index}>
                                                                <AccordionItemHeading>
                                                                    <AccordionItemButton>
                                                                        <span>{item.section_quetion}</span>
                                                                    </AccordionItemButton>
                                                                </AccordionItemHeading>
                                                                <AccordionItemPanel>
                                                                    <div dangerouslySetInnerHTML={{ __html: item.section_answer }}></div>

                                                                </AccordionItemPanel>
                                                            </AccordionItem>


                                                        ))}



                                                    </>
                                                </> : <> </>

                                                }
                                            </Accordion>
                                        </div>
                                    </div>
                                    <div className="col-lg-2"></div>
                                </div>
                            </div>
                        </div>

                        <Footer />

                    </>
            }
            {loader ? <Preloader /> : null}
        </>
    )
}
export default Patients;
