import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Alert from "@mui/material/Alert";
import PageContentLoader from "./PageContentLoader";
import {objectToQueryString} from "../../utils/helper";

const PaymentForm = ({ amount, clientSecret, successParams, onPaymentProcess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState("");
  const [processing, setProcessing] = useState(false);
  const [processMessage, setProcessMessage] = useState("");
  const [isStripeReady, setIsStripeReady] = useState(false);

  useEffect(() => {
    onPaymentProcess(processing);
  }, [processing]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log(`${window.location.origin}/payment-complete${objectToQueryString(successParams)}`)

    if (elements == null || stripe == null || processing) {
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError?.message) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    setProcessMessage("Your payment is being processed, please wait while we check");
    setProcessing(true);

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${window.location.origin}/payment-complete${objectToQueryString(successParams)}`,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
      setProcessing(false);
      setProcessMessage("");
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      console.log("Payment succeeded");
    }
  };

  const handleReady = () => {
    setIsStripeReady(true);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="text-center">
          <h3>Pay Amount: ${amount}.00</h3>
          </div>
        <PaymentElement onReady={handleReady} />
        <div className="text-center">
          {isStripeReady && (
            <button
              type="submit"
              disabled={!stripe || !elements}
              className="default-btn membr-btn w-100"
            >
              Pay
            </button>
          )}
        </div>
        {/* Show error message to your customers */}
        {/* {errorMessage && <Alert severity="error">{errorMessage}</Alert>} */}
        {errorMessage && <div className="error-payment" severity="error">{errorMessage}</div>}
      </form>
      <div>
      {processing && <PageContentLoader message={processMessage} />}</div>
    </>
  );
};

export default PaymentForm;
