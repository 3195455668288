import React, {useEffect, useState} from 'react';
import NavbarFive from "./Common/NavbarFive";
import aboutimg from '../styles/img/appointment-bg4.jpg';
import Preloader from "./Common/Preloader";
import testimonialimg from '../styles/img/testimonial_one.jpg';
import testimonialimgtwo from '../styles/img/testimonial_rwo.jpg';
import testimonialimgthree from '../styles/img/testimonail-three.jpg';
import testimonialfive from '../styles/img/testimonialfive.jpg';
import testimonialfour from '../styles/img/testimonialfour.jpg';
import testimonialsix from '../styles/img/testimonailsix.png';
import Footer from "./Common/Footer";
import { useSelector } from "react-redux";
import classNames from 'classnames';
import Pagination from './Common/Pagination';
import {getPaginatedItems} from '../utils/helper';
import { isComingSoon } from "../utils/helper";
import ComingSoon from "./Common/ComingSoon";
const Memberhighlight = () => {
  const { membershipData ,homeData} = useSelector((state) => state.pageContents);
  const [loader, setLoader] = React.useState(true);
  React.useEffect(() => {
    setTimeout(() => setLoader(false), 1500);
  }, []);
  const chunkSize = 2;

  const allMembers = membershipData ? membershipData.membershipmemeber : [];
  const [currentPageData, setCurrentPageData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalItems = allMembers.length;
  const itemsPerPage = 6;
  const handlePageChange = (pageNumber) => {
    setCurrentPageData(getPaginatedItems(allMembers, pageNumber, itemsPerPage));
    setCurrentPage(pageNumber);
  }

  useEffect(() => {
    setCurrentPageData(getPaginatedItems(allMembers, currentPage, itemsPerPage))
  }, [allMembers]);

  return (
    <>
     {
        homeData?.comingsoon && isComingSoon(homeData.comingsoon, 'Member Highlight') ?
          <ComingSoon />
          :
          <>
      <NavbarFive />

      <div className="page-title-area" style={{ backgroundImage: `url('${membershipData?.membershipheilightbanner?.banner_image}')` }}>
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Membership</h2>
                <ul>
                  <li><a href="/">Home</a></li>
                  <li className="active">{membershipData?.membershipheilightbanner?.banner_title}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="client-area news-mem-sec ptb-100">
        <div className="container">
          <div className="section-title bene-title">
            <h2>{membershipData?.membershipintro?.membership_section_title}</h2>
            <p className='member-para-sec members-margin'>
              {membershipData?.membershipintro?.membership_section_des}
            </p>
          </div>
          {
            currentPageData.map((item, index) => {
              if (index % chunkSize === 0) {
                const chunk = currentPageData.slice(index, index + chunkSize);
                return (
                  <div className={classNames("row", {
                    "new-member-sec": (currentPageData.length - 1) !== index
                  })} key={index}>
                    {chunk.map((chunkItem, chunkIndex) => (
                      <div className="col-lg-6 col-md-6 res-new-mem" key={chunkIndex}>
                        <div className="single-client mem-client mr-0 ml-0">
                          <img src={chunkItem.member_image} alt="img" />
                          <p>
                            {chunkItem.member_des}
                          </p>

                          {/* <ul>
                        <li>
                          <i className="bx bxs-star"></i>
                        </li>
                        <li>
                          <i className="bx bxs-star"></i>
                        </li>
                        <li>
                          <i className="bx bxs-star"></i>
                        </li>
                        <li>
                          <i className="bx bxs-star"></i>
                        </li>
                        <li>
                          <i className="bx bxs-star"></i>
                        </li>
                      </ul> */}

                          <h3>{chunkItem.member_title}</h3>
                          <span>{chunkItem.member_qualification}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                );
              }
              return null;
            })}

          <div className="row">
            <div className="col-lg-12">
              <Pagination
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                initialPage={currentPage}
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
      </>
      }
      {loader ? <Preloader /> : null}
    </>
  )
}
export default Memberhighlight;



