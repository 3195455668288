import React from "react";
import {
  Link,
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import facebookimg from '../../styles/img/facebook_white.png';
import twitterimgs from '../../styles/img/twitter-logo_white.png';
import whitelogo from '../../styles/img/logo.png';
import logonew from '../../../src/styles/img/smfalogo.png';

import logofootersec from '../../../src/styles/img/smfalogo-footeer.png';

import { useSelector } from "react-redux";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { homeData } = useSelector((state) => state.pageContents);

  return (
    <>
      
      <footer className="footer-top-area f-bg pt-100 pb-70">
      {
              homeData?.widgetdata &&
              <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div 
                className="single-widget "
                data-aos="fade-in"
                data-aos-delay="100"
                data-aos-duration="1200"
              >
                <Link href="#">
                  <img src={homeData.widgetdata.footer_logo} alt="Image"  className="fot-new-log"  />
              {/* <img src={logofootersec} className="fot-new-log" /> */}
                </Link>

                <p>
                {homeData.widgetdata.footer_section_description}
                </p>

                <div className="social-area">
                  <ul>
                    {/* <li>
                      <a href="https://www.facebook.com/" target="_blank" style={{backgroundColor:'transparent'}}>
                      <img src={facebookimg} style={{marginTop:'5px',height: '22px'}}/>
                      </a>
                    </li> */}
                    <li>
                      <a className="twit-white"   href={homeData.widgetdata.footer_twitter_link} target="_blank">
                      <img src={twitterimgs}  />
                      </a>
                    </li>
                    {/* <li>
                      <a href="https://www.linkedin.com/" target="_blank">
                        <i className="bx bxl-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebyoutubeook.com/"
                        target="_blank"
                      >
                        <i className="bx bxl-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/" target="_blank">
                        <i className="bx bxl-instagram"></i>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-1 col-md-6 col-sm-12 hide-sec">
              </div>

            <div className="col-lg-3 col-md-6 col-sm-12">
              <div 
                className="single-widget"
                data-aos="fade-in"
                data-aos-delay="200"
                data-aos-duration="1200"
              >
                <h3>Quick Links</h3>
                <ul>
                  <li>
                    <Link to="/contact">Contact us</Link>
                  </li>
                  <li>
                    <Link to="/onlineapplication">Join SMFA</Link>
                  </li>
                  <li>
                    <Link href="#">Renew Membership</Link>
                  </li>
                  <li>
                    <Link to="/meeting">Next Meeting</Link>
                  </li>
                  <li>
                    <Link to="/privacypolicy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/cancellationpolicy">Cancellation Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-1 col-md-6 col-sm-12 hide-sec">
              </div>

            {/* <div className="col-lg-4 col-md-6">
              <div 
                className="single-widget open-time"
                data-aos="fade-in"
                data-aos-delay="300"
                data-aos-duration="1200"
              >
                <h3>Opening Hours</h3>
                <ul>
                  <li>
                    <span>Mon-Tue:</span>
                    <span className="right">6:00 AM - 10:00 PM</span>
                  </li>
                  <li>
                    <span>Wed-Thu:</span>
                    <span className="right">6:00 AM - 10:00 PM</span>
                  </li>
                  <li>
                    <span>Fry:</span>
                    <span className="right">6:00 AM - 04:00 PM</span>
                  </li>
                  <li>
                    <span>Sun:</span>
                    <span className="right">Closed</span>
                  </li>
                </ul>
              </div>
            </div> */}

            <div className="col-lg-3 col-md-6 col-sm-12">
              <div 
                className="single-widget contact"
                data-aos="fade-in"
                data-aos-delay="400"
                data-aos-duration="1200"
              >
                <h3>Get In Touch</h3>
                <ul>
                  {/* <li>
                    <a href="tel:+822456974">
                      <i className="bx bx-phone-call"></i>
                      <span>Helpline:</span>
                      Phone: +822456974
                    </a>
                  </li> */}
                  <li>
                    <a href={`mailto:${homeData.widgetdata.footer_email_id}`}>
                      <div className="ftr-sec">
                      <i className="bx bx-envelope"></i>
                      <span>Email:</span>
                      </div>
                     <span className="ftr-email">{homeData.widgetdata.footer_email_id}</span> 
                    </a>
                  </li>
                  {/* <li>
                    <i className="bx bx-location-plus"></i>
                    <span>Address:</span>
                    3105 S Peoria St, Aurora, Colorado, United States, 80014
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
            }
      </footer>

      <div className="footer-bottom-area">
        <div className="container">
          <div className="copy-right">
            <p>
              Copyright &copy; {currentYear} {" "}
              <a href="#" target="_self">
              SMFA
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
