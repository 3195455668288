import React, { useState } from "react";
import NavbarFive from "../components/Common/NavbarFive";
import aboutimg from '../styles/img/appointment-bg4.jpg';
import Preloader from "./Common/Preloader";
import Footer from "../components/Common/Footer";
import { useSelector } from "react-redux";
import classNames from 'classnames';
import ReadMore from "./Common/ReadMore";
import { isComingSoon } from "../utils/helper";
import ComingSoon from "./Common/ComingSoon";
const Membershipcategories = () => {
  const { membershipData ,homeData} = useSelector((state) => state.pageContents);
  const [loader, setLoader] = React.useState(true);
  React.useEffect(() => {
    setTimeout(() => setLoader(false), 1500);
  }, []);

  return (
    <>
     {
        homeData?.comingsoon && isComingSoon(homeData.comingsoon, 'Membership Categories') ?
          <ComingSoon />
          :
          <>
      <NavbarFive />
      <div className="page-title-area" style={{ backgroundImage: `url('${membershipData?.membershipcategoriesbanner?.banner_image}')` }}>
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Membership</h2>
                <ul>
                  <li><a href="/">Home</a></li>
                  <li className="active">{membershipData?.membershipcategoriesbanner?.banner_title}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='mem-category-sec  ptb-100'>
        <div className="container">
          <div className="section-title  bene-title cate-title">
            <h2>{membershipData?.membershipcategories?.section_title}</h2>
            <p className='member-para-sec'>
              {membershipData?.membershipcategories?.section_description}
            </p>
          </div>
          {
            membershipData?.categoriesmembers.map((item, index) => (
              <div className={classNames('row', {
                "category-top": index !== 0
              })} key={index}>
                <div className='col-md-12'>
                  <div className="mem-category-main-sec">
                    <h2>{item.section_title}</h2>
                    <ReadMore>{item.section_description}</ReadMore>
                  </div>
                </div>
              </div>
            ))
          }


          {/* <div className='row category-top'>
                <div className='col-md-12'>
                    <div className="mem-category-main-sec">
                        <h2>Active/Affiliate Joint Membership with CCAS</h2>
                        <ReadMore> It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable.</ReadMore> </div>


                </div>
                </div>


                <div className='row category-top'>
                <div className='col-md-12'>
                    <div className="mem-category-main-sec">
                        <h2>Retired</h2>
                        <ReadMore> Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur

                        </ReadMore>
                    </div>
                </div>
                </div> */}



        </div>
      </div>
      <Footer />
      </>
      }
      {loader ? <Preloader /> : null}
    </>
  )
}
export default Membershipcategories;
