import React, { useState, useEffect } from "react";
// import { useRouter } from "next/router";
import {
  Link,
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import TopHeader from "./TopHeader";
import mainlog from '../../styles/img/logo-two.png';
// import logonew from 'styles/img/logo-two.png';
import logonew from '../../../src/styles/img/smfalogo_one.png';
import { useSelector } from "react-redux";

const NavbarFive = () => {
  // Add active class
  const [currentPath, setCurrentPath] = useState("");
  const { homeData } = useSelector((state) => state.pageContents);

  // const router = useRouter();
  // console.log(router.asPath)
  useEffect(() => {
    setCurrentPath(window.location.pathname);
  }, [window.location.pathname]);

  const [menu, setMenu] = React.useState(true);

  const toggleNavbar = () => {
    setMenu(!menu);
  };

  React.useEffect(() => {
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
  });

  const classOne = menu
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show";
  const classTwo = menu
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  return (
    <>
      <header className="header-area fixed-top">

        <TopHeader />

        <div className="nav-area five">
          <div id="navbar" className="navbar-area">
            <div className="main-nav">
              <nav className="navbar navbar-expand-md navbar-light res-btn-navbar">
                <div className="container">
                  <Link  to="/" className="navbar-brand navbar-logoss">
                  {
              homeData?.widgetdata &&
              <img src={homeData.widgetdata.header_logo} alt="logo" />
                  }

                  </Link>

                  <button
                    onClick={toggleNavbar}
                    className={classTwo}
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="icon-bar top-bar"></span>
                    <span className="icon-bar middle-bar"></span>
                    <span className="icon-bar bottom-bar"></span>
                  </button>

                  <div className={classOne} id="navbarSupportedContent">
                    <ul className="navbar-nav m-auto">
                      <li className="nav-item">
                        <Link to="/" className="nav-link" >Home</Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/about-us" className="nav-link">
                          About
                        </Link>
                      </li>

                      {/* <li className="nav-item">
                        <Link href="#" onClick={(e) => e.preventDefault()} className="nav-link" >
                          Committees <i className="bx bx-plus"></i>
                        </Link>

                        <ul className="dropdown-menu">
                          <li className="nav-item">
                           <li className="nav-item">
                              <Link to="/education-meeting-planning-committee" className="nav-link" >
                                Education and Meeting Planning Committee
                              </Link>
                          </li>
                          </li>

                          <li className="nav-item">
                            <Link to="/finance-membership-committee" className="nav-link" >
                                Finance and Membership Committee
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link to="/research-committee" className="nav-link" >
                              Research Committee
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link to="/intersociety-committee" className="nav-link" >
                                Intersociety Committee
                            </Link>
                          </li>

                        </ul>

                      </li> */}

                      <li className="nav-item">
                      <Link to="/affiliates" className="nav-link">
                          Affiliates
                        </Link>

                      </li>

                      <li className="nav-item">
                        <Link href="#" onClick={(e) => e.preventDefault()} className="nav-link">
                          Membership <i className="bx bx-plus"></i>
                        </Link>
                        <ul className="dropdown-menu">
                          <li className="nav-item">
                            <Link to="/benefitsmembership" className="nav-link">
                              Benefits of Membership
                            </Link>
                          </li>

                          {/* <li className="nav-item">
                            <Link to="/memberhighlight" className="nav-link" >
                               Member Highlight
                            </Link>
                          </li> */}

                          <li className="nav-item">
                            <Link to="/membershipcategories" className="nav-link" >
                              Membership Categories
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link to="/onlineapplication" className="nav-link" >
                              Online Application
                            </Link>
                          </li>
                          {/* <li className="nav-item">
                            <Link to="/" className="nav-link" >
                            Printable application
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/" className="nav-link" >
                              Renew Membership
                            </Link>
                          </li> */}
                          <li className="nav-item">
                            <Link to="/getinvolved" className="nav-link" >
                             Get Involved
                            </Link>
                          </li>

                        </ul>

                      </li>


                      {/* <li className="nav-item">
                        <Link
                          href="#"
                          onClick={(e) => e.preventDefault()}
                          className="nav-link"
                        >
                          Meetings

                        </Link>


                      </li> */}


                    <li className="nav-item">

                      <Link to="/meeting" className="nav-link">
                      Meetings
                      </Link>
                    </li>




                      <li className="nav-item">
                        <Link
                          href="#"
                          onClick={(e) => e.preventDefault()}
                          className="nav-link"
                        >
                          Resources <i className="bx bx-plus"></i>
                        </Link>

                        <ul className="dropdown-menu">
                          <li className="nav-item">
                            <Link
                             to="/fiesta-sessions"

                            >
                              Fiesta Sessions
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link
                             to="/guidelines"
                            >
                              Guidelines
                            </Link>
                          </li>




                        </ul>
                      </li>

                      {/* <li className="nav-item">
                        <Link
                          href="#"
                          onClick={(e) => e.preventDefault()}
                          className="nav-link"
                        >
                          Patients
                        </Link>
                      </li> */}
                       {/*<li className="nav-item">

                        <Link to="/patients" className="nav-link">
                        Patients
                        </Link>
                       </li>*/}



                      <li className="nav-item">
                        {/* <Link
                          href=""
                          className={`nav-link ${currentPath == "/contact/" && "active"
                            }`}
                        >
                          Contact Us
                        </Link> */}
                         <Link to="/contact" className="nav-link">
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="others-option">
                    <div className="subscribe">
                      <Link to="/membership-login" className="default-btn">
                        Member Login
                      </Link>
                    </div>
                  </div>

                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default NavbarFive;
