import React from "react";
import NavbarFive from "./Common/NavbarFive";
import MainBanner from "./Home/MainBanner";
import FeaturedService from "./Home/FeaturedService";
import OurMission from "./Home/OurMission";
import BestService from "./Home/BestService";
import AppointmentForm from "./Home/AppointmentForm";
import NewsStyleOne from "./Common/NewsStyleOne";
import Footer from "./Common/Footer";
import GoTop from "./Common/GoTop";
import Preloader from "./Common/Preloader";

const Index = () => {
  const [loader, setLoader] = React.useState(true);
  React.useEffect(() => {
    setTimeout(() => setLoader(false), 1500);
  }, []);

  return (
    <>
      <NavbarFive />

      <MainBanner />

      <FeaturedService />

      <OurMission />

      {/* <UpcomingCampaign /> */}


      {/* <NewsStyleOne /> */ }
      {/* <DoctorsStyleThree /> */}

      {/* <AboutUs /> */}

      <BestService />
      {/* <AppointmentForm /> */}

      {/* <Subscribe /> */}

      <Footer />
      {loader ? <Preloader /> : null}

      <GoTop scrollStepInPx="100" delayInMs="10.50" />
    </>
  );
};

export default Index;
