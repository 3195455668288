import React from "react";
import {
  Link,
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import serviceone from '../../styles/img/home/home-six-service1.jpg';
import servicetwo from '../../styles/img/home/home-six-service2.jpg';
import servicethree from '../../styles/img/home/home-six-service3.jpg';
import { useSelector } from "react-redux";

const BestService = () => {
  const { homeData } = useSelector((state) => state.pageContents);
  const fontIcons = ['flaticon-heart-2', 'flaticon-test-tube', 'flaticon-man'];

  return (
    <>
      {
        (homeData?.homeinvolve || homeData?.involvedata.length) &&

        <div className="best-services-area pt-100 pb-70">
          <div className="container">
            {
              homeData?.homeinvolve &&
              <div className="section-title">
                {/* <span className="top-title">Event</span> */}
                <h2>{homeData.homeinvolve.involve_title}</h2>
                <p>
                  {homeData.homeinvolve.involve_short_description}
                </p>
              </div>
            }
            {
              homeData?.involvedata.length &&
              <div className="row justify-content-center">
                {
                  homeData?.involvedata.map((item, index) => (
                    <div className="col-lg-4 col-sm-6" key={index}>
                      <div className="single-best-services">
                        <img src={item.involve_image} alt="Image" />
                        {/* <p className="illi-secs">Illustration Only</p> */}
                        <div className="best-services-content">
                          <h3>{item.involve_title}</h3>
                          <p>
                            {item.involve_short_description}
                          </p>

                          {/* <Link href="/service-details" className="read-more"> */}
                          <Link to={`/getinvolved#getinvolved-${item.involve_id}`} className="read-more">
                            Read More <i className="bx bx-plus"></i>
                          </Link>
                          {
                            fontIcons[index] &&
                            <span className={fontIcons[index]}></span>
                          }
                        </div>
                      </div>
                    </div>
                  ))
                }

              </div>
            }
          </div>
        </div>
      }
    </>
  );
};

export default BestService;
