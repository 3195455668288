import React from "react";
import {
  Link,
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import serviceicon from '../../styles/img/home-six-service-icon1.png';
import donateicon from '../../styles/img/donate-icons.png';
import { useSelector } from "react-redux";

const FeaturedService = () => {
  const { homeData } = useSelector((state) => state.pageContents);

  return (
    <>
      {
        homeData?.homejoin &&

        <div className="second-facility-area six pt-100 pb-70">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-2 col-sm-6 hide-sec"></div>
              <div className="col-lg-4 col-sm-6">
                <div className="second-facility-item">
                  <img
                    src={homeData.homejoin.image}
                    alt="Image"
                  />
                  <h3>{homeData.homejoin.title}</h3>
                  <p>
                    {homeData.homejoin.short_description}
                  </p>

                  {/* <Link href="/service-details" className="read-more"> */}
                  <Link to="/onlineapplication" className="read-more">
                    Read More <i className="bx bx-plus"></i>
                  </Link>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6">
                <div className="second-facility-item">
                  <img
                    src={homeData.homejoin.image_mob}
                    alt="Image"
                  />
                  <h3>{homeData.homejoin.title_sc}</h3>
                  <p>
                    {homeData.homejoin.short_description_sc}
                  </p>

                  {/* <Link href="/service-details" className="read-more"> */}
                  <Link to="/onlineapplication" className="read-more">
                    Read More <i className="bx bx-plus"></i>
                  </Link>
                </div>
              </div>

              <div className="col-lg-2 col-sm-6 hide-sec"></div>

              {/* <div className="col-lg-4 col-sm-6">
              <div className="second-facility-item">
                <img
                  src="smfa/img/home-six/home-six-service-icon3.png"
                  alt="Image"
                />
                <h3>Emergency Service</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore.
                </p>

                <Link href="/service-details" className="read-more">
                  Read More <i className="bx bx-plus"></i>
                </Link>
              </div>
            </div> */}


            </div>
          </div>
        </div>
      }
    </>
  );
};

export default FeaturedService;
