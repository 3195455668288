import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    homeData: null,
    aboutData: null,
    committeesData: null,
    affiliatesData: null,
    membershipData: null,
    meetingsData: null,
    resourcesData: null,
    patientsData: null,
    contactusData: null,
    fiestaData: null,
    privacypolicyData: null,
    cancelpolicyData: null,
}

export const pageContentSlice = createSlice({
    name: 'pageContents',
    initialState,
    reducers: {
        setPageContent: (state, action) => {
            state[`${action.payload.key}Data`] = action.payload.data
        },
    }
})

export const { setPageContent } = pageContentSlice.actions

export default pageContentSlice.reducer
