import React, { useState } from "react";
import NavbarFive from "./Common/NavbarFive";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/Common/Footer";
import captcha_new from '../styles/img/captcha_new.jpg';
import Preloader from "./Common/Preloader";
import membershipimg from '../styles/img/appointment-bg4.jpg';
import { updateNullOfObjectValues, isEmail, isPhoneNumber } from '../utils/helper';
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector } from "react-redux";
import classNames from 'classnames';
import { contactUsSubmit } from '../actions/pagecontent.actions';
import { isComingSoon, generateUniqueToken } from "../utils/helper";
import ComingSoon from "./Common/ComingSoon";

// modal section start
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

// modal section end

import {
  Navigate,
  Link,
  useNavigate,
  NavLink
} from "react-router-dom";
// modal section start
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

// modal section end

const Contact = () => {
  const recaptchaRef = React.useRef();
  const formFields = {
    name: '',
    email: '',
    phone_number: '',
    // subject: '',
    message: '',
    recaptcha: ''
  }
  const [loader, setLoader] = React.useState(true);
  const { contactusData, homeData } = useSelector((state) => state.pageContents);
  const { ip_address } = useSelector((state) => state.userdata);
  const [formValues, setFormValues] = React.useState(formFields);
  const [formErrors, setFormErrors] = React.useState(updateNullOfObjectValues(formFields));
  const [submitting, setSubmitting] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  let navigate = useNavigate();
  React.useEffect(() => {
    setTimeout(() => setLoader(false), 1500);
    console.log('generateUniqueToken', generateUniqueToken());
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: null });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (submitting) return;
    if (validateForm()) {
      setSubmitting(true);
      setErrorMessage('');
      setSuccessMessage('');
      let formData = { ...formValues, twitter_link: homeData?.widgetdata?.footer_twitter_link, ip_address: ip_address };
      formData.recaptcha = recaptchaRef.current.getValue();
      contactUsSubmit(formData)
        .then((response) => {
          setSubmitting(false);
          if (response.data.status) {
            //setSuccessMessage(response.data.message);
            setFormValues(formFields);
            recaptchaRef.current.reset();
            // setTimeout(() => {
            //   setSuccessMessage('');
            // }, 2000);
            setOpen(true);
          } else {
            setErrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          setSubmitting(false);
          setErrorMessage('Something went wrong. Please try again later.');
        });
    }
  }

  const validateForm = () => {
    let isValid = true;
    let errors = {};
    Object.keys(formValues).forEach((key) => {
      if (!formValues[key] && key !== 'recaptcha') {
        isValid = false;
        errors[key] = 'This field is required';
      } else if (key === 'email' && !isEmail(formValues[key])) {
        isValid = false;
        errors[key] = 'Invalid email address';
      } else if (key === 'phone_number' && !isPhoneNumber(formValues[key])) {
        isValid = false;
        errors[key] = 'Invalid phone number';
      }
    });
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      isValid = false;
      errors.recaptcha = 'Please verify captcha';
    }
    setFormErrors(errors);
    return isValid;
  }

  // modal section start

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    //setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // modal section end
  const handleregis = () => {
    navigate(`/register`);
  }
  return (


    <>
      {
        homeData?.comingsoon && isComingSoon(homeData.comingsoon, 'Contact') ?
          <ComingSoon />
          :
          <>
            <NavbarFive />


            {
              contactusData?.contactbanner &&
              <>

                <div className="page-title-area" style={{ backgroundImage: `url('${contactusData.contactbanner.banner_image}')` }}>
                  <div className="d-table">
                    <div className="d-table-cell">
                      <div className="container">
                        <div className="page-title-content">
                          <h2>{contactusData.contactbanner.banner_title}</h2>
                          <ul>
                            <li><a href="/">Home</a></li>
                            <li className="active">{contactusData.contactbanner.banner_title}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }


            {/* <ContactForm />  */}

            {/* contact form section start */}

            <div className="main-contact-area contact ptb-100">
              <div className="container">
                {
                  contactusData?.contactquery &&
                  <>

                    <div className="section-title">
                      <span className="top-title">{contactusData.contactquery.section_title}</span>
                      <h2>{contactusData.contactquery.section_sub_title}</h2>
                      <p>
                        {contactusData.contactquery.section_description}
                      </p>
                    </div>
                  </>
                }

                <div className="contact-wrap contact-pages mb-0">
                  <div className="contact-form">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-4 col-md-4">
                          <div className="form-group vali-sec">
                            <label>Name</label>
                            <input
                              type="text"
                              name="name"
                              placeholder="Your Name"
                              className={classNames("form-control", { 'is-invalid': formErrors.name })}
                              onChange={handleInputChange}
                              value={formValues.name}
                            />
                            {
                              formErrors.name &&
                              <div className="invalid-feedback">
                                {formErrors.name}
                              </div>
                            }
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                          <div className="form-group vali-sec">
                            <label>Email</label>
                            <input
                              type="text"
                              name="email"
                              placeholder="Your Email"
                              className={classNames("form-control", { 'is-invalid': formErrors.email })}
                              onChange={handleInputChange}
                              value={formValues.email}
                            />
                            {
                              formErrors.email &&
                              <div className="invalid-feedback">
                                {formErrors.email}
                              </div>
                            }
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                          <div className="form-group vali-sec">
                            <label>Phone Number</label>
                            <input
                              type="text"
                              name="phone_number"
                              placeholder="Phone Number"
                              className={classNames("form-control", { 'is-invalid': formErrors.phone_number })}
                              onChange={handleInputChange}
                              value={formValues.phone_number}
                            />
                            {
                              formErrors.phone_number &&
                              <div className="invalid-feedback">
                                {formErrors.phone_number}
                              </div>
                            }
                          </div>
                        </div>

                        {/* <div className="col-lg-6 col-md-6">
                    <div className="form-group vali-sec">
                      <label>Subject</label>
                      <input
                        type="text"
                        name="subject"
                        placeholder="Subject"
                        className={classNames("form-control", { 'is-invalid': formErrors.subject })}
                        onChange={handleInputChange}
                        value={formValues.subject}
                      />
                      {
                        formErrors.subject &&
                        <div className="invalid-feedback">
                          {formErrors.subject}
                        </div>
                      }
                    </div>
                  </div> */}

                        <div className="col-lg-12 col-md-12">
                          <div className="form-group vali-sec">
                            <label>Message</label>
                            <textarea
                              cols="30"
                              rows="6"
                              placeholder="Write your message..."
                              name="message"
                              className={classNames("form-control", { 'is-invalid': formErrors.message })}
                              onChange={handleInputChange}
                              value={formValues.message}
                            />
                            {
                              formErrors.message &&
                              <div className="invalid-feedback">
                                {formErrors.message}
                              </div>
                            }
                          </div>
                        </div>

                        {/* <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                    <img src={captcha_new} alt="captcha" style={{height:'65px'}} />
                    </div>
                  </div> */}

                        <div className="col-lg-6 col-sm-6">
                          <div className="form-group" style={{ marginBottom: '0px' }}>
                            {/* <img src={captcha_new} alt="captcha" style={{ height: '65px' }} /> */}
                            <ReCAPTCHA
                              ref={recaptchaRef}
                              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            />
                            {
                              formErrors.recaptcha &&
                              <div className="invalid-feedback" style={{ display: 'block' }}>
                                {formErrors.recaptcha}
                              </div>
                            }
                          </div>
                        </div>

                        <div className="col-lg-6 col-sm-6">
                          <button type="submit" className="default-btn btn-two" onClick={handleClickOpen}>
                            Send Message {submitting && <div className="spinner-border spinner-border-sm" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>}
                          </button>
                        </div>

                        {/* dialog section start */}
                        {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
       {/* dialog section end */}
                      </div>
                    </form>

                    <div className='validate-sec'>
                      {
                        successMessage &&
                        <div className="">
                          <p className="succ-msg">{successMessage}
                          </p>
                        </div>
                      }
                      {
                        errorMessage &&
                        <div className="">
                          <p className="error-msg">{errorMessage}
                          </p>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* contact info section end */}

            <Footer />

          </>
      }
      {loader ? <Preloader /> : null}

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open} className="modal-content-new"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">

        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
           <div className="modal-sec">
            <div className="row">
              <div className="col-md-5">
                <div className="img-mod-sec">

                  <a href="https://www.royalcg.com/"><img src="https://s3-dev-sms.s3.ap-south-1.amazonaws.com/uploads/1719212972_doctor11.png" /></a>

                </div>

              </div>
              <div className="col-md-7">

                <div className="modal-contentss">
                  <h2>Thank You !!</h2>
                  <p>We will get back to you soon</p>

                  <p>While you wait, why not <span style={{color:'#0f9e9d'}}>REGISTER</span> with us</p>
                  <button onClick={() => handleregis()} className="default-btn btn-two btn-regis">Register Now</button>
                  </div>

                </div>
            </div>
           </div>
          </Typography>

        </DialogContent>

      </BootstrapDialog>
    </>
  );
};

export default Contact;
