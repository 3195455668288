import React from "react";

import "./App.css";
import AOS from "aos";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppContext } from "./context";
import Home from "./components/index";
import AboutUs from "./components/AboutUs";
import Contact from "./components/contact";
import Meeting from "./components/meeting";
import Patients from "./components/patients";
import Benefitsmembership from "./components/benefitsmembership";
import Memberhighlight from "./components/memberhighlight";
import Membershipcategories from "./components/membershipcategories";
import Onlineapplication from "./components/onlineapplication";
import Getinvolved from "./components/getinvolved";
import EducationMeetingPlanningCommittee from "./components/education-meeting-planning-committee";

import FinanceMembershipCommittee from "./components/finance-membership-committee";
import ResearchCommittee from "./components/research-committee";
import IntersocietyCommittee from "./components/intersociety-committee";

import FeistaSession from "./components/feista-session";
import Guidelines from "./components/guidelines";

import Register from "./components/register";
import Affiliates from "./components/affiliates";
import MembershipLogin from "./components/membershipLogin";
import PrivacyPolicy from "./components/privacypolicy";
import CancellationPolicy from "./components/cancellationpolicy";
import {
  getHomePageContent,
  getAboutPageContent,
  getComAffMemPageContent,
  getMeetPatConPageContent,
} from "./actions/pagecontent.actions";
import { setPageContent } from "./features/pageContentSlice";
import { setFormToken, setIpAddress } from "./features/userdataSlice";
import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "./utils/ScrollToTop";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { generateUniqueToken } from "./utils/helper";
import axios from "axios";
import PaymentComplete from "./components/PaymentComplete";

function App() {
  const dispatch = useDispatch();
  const formToken = useSelector((state) => state.userdata.formToken);
  React.useEffect(() => {
    AOS.init();

    /**
     * Load Contens of all pages
     */
    getHomePageContent()
      .then((res) => {
        if (res.data.status) {
          dispatch(setPageContent({ key: "home", data: res.data.data }));
        }
      })
      .catch((error) => {
        console.log(error);
      });

    getAboutPageContent()
      .then((res) => {
        if (res.data.status) {
          dispatch(setPageContent({ key: "about", data: res.data.data.about }));
          dispatch(setPageContent({ key: "fiesta", data: res.data.data.fiesta }));
          dispatch(setPageContent({ key: "privacypolicy", data: res.data.data.privacypolicy }));
          dispatch(setPageContent({ key: "cancelpolicy", data: res.data.data.cancelpolicy }));
        }
      })
      .catch((error) => {
        console.log(error);
      });

    getComAffMemPageContent()
      .then((res) => {
        if (res.data.status) {
          dispatch(
            setPageContent({
              key: "committees",
              data: res.data.data.commiteepage,
            })
          );
          dispatch(
            setPageContent({
              key: "affiliates",
              data: res.data.data.affiliatepage,
            })
          );
          dispatch(
            setPageContent({
              key: "membership",
              data: res.data.data.membershipbpage,
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });

    getMeetPatConPageContent()
      .then((res) => {
        if (res.data.status) {
          dispatch(
            setPageContent({
              key: "meetings",
              data: res.data.data.meetingbpage,
            })
          );
          dispatch(
            setPageContent({ key: "patients", data: res.data.data.patientpage })
          );
          dispatch(
            setPageContent({
              key: "contactus",
              data: res.data.data.contactpage,
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });

    if (!formToken) {
      const token = generateUniqueToken();
      dispatch(setFormToken(token));
    }

    //get user ip
    loadIp();
  }, []);

  const loadIp = () => {
    axios
      .get("https://ipinfo.io/json")
      .then((res) => {
        dispatch(setIpAddress(res.data.ip));
      })
      .catch((err) => {
        loadIp();
      });
  };

  var data = [];

  const [storage, setStorage] = React.useState([data]);

  const dispatchStorageEvent = (payload) => {
    setStorage(payload);

    return true;
  };

  const handleAcceptCookie = () => { };

  var baseName = "/";
  return (
    <>
      <AppContext.Provider value={{ storage, dispatchStorageEvent }}>
        <BrowserRouter basename={baseName}>
          <ScrollToTop />
          <Routes>
            <Route
              path="/"
              element={
                <div>
                  <Home />
                </div>
              }
            />
            <Route
              path="/about-us"
              element={
                <div>
                  <AboutUs />
                </div>
              }
            />
            <Route
              path="/contact"
              element={
                <div>
                  <Contact />
                </div>
              }
            />
            <Route
              path="/meeting"
              element={
                <div>
                  <Meeting />
                </div>
              }
            />
            <Route
              path="/patients"
              element={
                <div>
                  <Patients />
                </div>
              }
            />
            <Route
              path="/benefitsmembership"
              element={
                <div>
                  <Benefitsmembership />
                </div>
              }
            />
            <Route
              path="/memberhighlight"
              element={
                <div>
                  <Memberhighlight />
                </div>
              }
            />
            <Route
              path="/membershipcategories"
              element={
                <div>
                  <Membershipcategories />
                </div>
              }
            />
            <Route
              path="/onlineapplication"
              element={
                <div>
                  <Onlineapplication />
                </div>
              }
            />
            <Route
              path="/getinvolved"
              element={
                <div>
                  <Getinvolved />
                </div>
              }
            />
            <Route
              path="/education-meeting-planning-committee"
              element={
                <div>
                  <EducationMeetingPlanningCommittee />
                </div>
              }
            />
            <Route
              path="/finance-membership-committee"
              element={
                <div>
                  <FinanceMembershipCommittee />
                </div>
              }
            />
            <Route
              path="/research-committee"
              element={
                <div>
                  <ResearchCommittee />
                </div>
              }
            />

            <Route
              path="/intersociety-committee"
              element={
                <div>
                  <IntersocietyCommittee />
                </div>
              }
            />
            <Route
              path="/affiliates"
              element={
                <div>
                  <Affiliates />
                </div>
              }
            />


              <Route
                path="/fiesta-sessions"
                element={
                  <div>
                    <FeistaSession />
                  </div>
                }
              />
              <Route
                path="/guidelines"
                element={
                  <div>
                    <Guidelines />
                  </div>
                }
              />


            <Route
              path="/register"
              element={
                <div>
                  <Register />
                </div>
              }
            />

            <Route
              path="/membership-login"
              element={
                <div>
                  <MembershipLogin />
                </div>
              }
            />

            <Route
              path="/payment-complete"
              element={
                <div>
                  <PaymentComplete />
                </div>
              }
            />


            <Route
              path="/privacypolicy"
              element={
                <div>
                  <PrivacyPolicy />
                </div>
              }
            />
              <Route
                path="/cancellationpolicy"
                element={
                  <div>
                    <CancellationPolicy />
                  </div>
                }
              />
          </Routes>
        </BrowserRouter>

        <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="smfacookieconsent"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
          onAccept={handleAcceptCookie}
          enableDeclineButton
          declineButtonText="Reject"
          buttonClasses='accptbtn'
          declineButtonClasses="declinbtn"
        >
          We value your privacy. These cookies help us personalize your
          experience.
        </CookieConsent>
      </AppContext.Provider>
    </>
  );
}

export default App;
