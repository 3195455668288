import axios from './axios';

export const getHomePageContent = () => {
    return axios.get('/homepage');
}

export const getAboutPageContent = () => {
    return axios.get('/aboutpage');
}

export const getComAffMemPageContent = () => {
    return axios.get('/com-af-mem-pages');
}

export const getMeetPatConPageContent = () => {
    return axios.get('/meet-pat-con-pages');
}

export const getformupmeet = (id) => {
    return axios.get('/downloadupmeeting/'+id,{
        responseType: 'blob',
      });
}

export const getformprevmeet = (id) => {
    return axios.get('/downloadpremeeting/'+id,{
        responseType: 'blob',
      });
}

export const contactUsSubmit = (data) => {
    return axios.post('/contactform', data);
}

export const onlineApplicationSubmit = (data) => {
    return axios.post('/onlineapplication', data);
}

export const registationSubmit = (data) => {
    return axios.post('/registation', data);
}

export const getExistingRegistation = (data) => {
    return axios.post('/existing-registation', data);
}
