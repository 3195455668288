import React, { useState } from "react";
import NavbarFive from "./Common/NavbarFive";
import aboutimg from '../styles/img/appointment-bg4.jpg';
import docimg from '../styles/img/doctor13.jpg';
import Preloader from "./Common/Preloader";
import Footer from "../components/Common/Footer";
import { useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import { isComingSoon } from "../utils/helper";
import ComingSoon from "./Common/ComingSoon";
import "react-multi-carousel/lib/styles.css";

const EducationMeetingPlanningCommittee = () => {
    const { committeesData, homeData } = useSelector((state) => state.pageContents);
    const [loader, setLoader] = React.useState(true);
    React.useEffect(() => {
        setTimeout(() => setLoader(false), 1500);
    }, []);

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 4 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 464 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        }

    }

    return (
        <>
            {
                homeData?.comingsoon && isComingSoon(homeData.comingsoon, 'Education and Meeting Planning Committee') ?
                    <ComingSoon />
                    :
                    <>
                        <NavbarFive />
                        <div className="page-title-area" style={{ backgroundImage: `url('${committeesData?.commiteeplanningbanner?.banner_image}')` }}>
                            <div className="d-table">
                                <div className="d-table-cell">
                                    <div className="container">
                                        <div className="page-title-content">
                                            <h2>Committees</h2>
                                            <ul>
                                                <li><a href="/">Home</a></li>
                                                <li className="active">{committeesData?.commiteeplanningbanner?.banner_title}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="how-its-work involve-top committes-sec">
                            <div className="container">
                                <div className='row'>
                                    <div className="col-md-12">
                                        <div className="about-top-sec committe-head">
                                            <h2>{committeesData?.commiteeplanning?.section_title}</h2>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-3'>
                                        {/* <div className='commitee-img'>
                                <img src={docimg} alt="Doctor" />
                        </div> */}
                                        <div className='doctors-sidebar'>
                                            <div className='doctors-img commitee-img'>
                                                <img src={committeesData?.commiteeplanning?.section_image} alt="Doctor" />


                                            </div>
                                        </div>

                                    </div>
                                    <div className='col-lg-9 col-md-9'>
                                        <div className='doctors-detailss committes-detailss'>
                                            <div className='doctors-history'>
                                                {/* <h2>Chair: Gordon Shumway, MD</h2> */}
                                                <div className="row borders">
                                                    <div className="col-lg-3 pl-0">
                                                        <div className="left-title">
                                                            <h3>Chair</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-9">
                                                        <div className="right-title">
                                                            <ul>
                                                                <li><i className="bx bxs-hand-right"></i>{committeesData?.commiteeplanning?.chair}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row borders">
                                                    <div className="col-lg-3 pl-0">
                                                        <div className="left-title">
                                                            <h3>Vice Chair</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-9">
                                                        <div className="right-title">
                                                            <ul>
                                                                <li><i className="bx bxs-hand-right"></i>{committeesData?.commiteeplanning?.vice_chair}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="row borders" style={{ borderBottom: 'none', paddingBottom: '0px' }}>
                                                    <div className="col-lg-3 pl-0">
                                                        <div className="left-title">
                                                            <h3>Newsletter Editor</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-9">
                                                        <div className="right-title">
                                                            <ul>
                                                                <li><i className="bx bxs-hand-right"></i>{committeesData?.commiteeplanning?.news_leeter_editor}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='commitee-inner-sec'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='committee-sec-inner'>
                                                <div dangerouslySetInnerHTML={{ __html: committeesData?.commiteeplanning?.description }}>

                                                </div>
                                                {/*<p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form,
                                        by injected humour, or randomised words which don't look even slightly believable.
                                        If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.
                                    </p>

                                    <div className='committee-points'>
                                        <p>In detail, the role of this committee will be too:</p>
                                        <ul>
                                            <li>Create content for a regular newsletter. </li>
                                            <li>Maintain an engaging, user-friendly website.</li>
                                            <li>I must explain to you how all this mistaken idea of reprobating pleasure and extolling pain arose</li>
                                            <li>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout</li>
                                        </ul>
                                    </div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            committeesData?.CommitiMember?.length &&
                            <div className='Committee-members-sec' >
                                <div className='container'>
                                    <div className='row'>
                                        <div className="parent">
                                            <h2>Committee Members</h2>
                                            <Carousel
                                                responsive={responsive}
                                                autoPlay={false}
                                                swipeable={true}
                                                draggable={true}
                                                showDots={true}
                                                infinite={true}
                                                partialVisible={false}
                                                dotListclassName="custom-dot-list-style"
                                            >
                                                {committeesData.CommitiMember.map((item, index) => {
                                                    return (
                                                        <div className="slider" key={index}>
                                                            {/* <img src={imageUrl.url} alt="members" /> */}
                                                            <div className="slider-sec">
                                                                {item.section_title} {item.section_qualification}
                                                                <p>{item.section_des}</p>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </Carousel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <Footer />
                    </>
            }
            {loader ? <Preloader /> : null}
        </>
    )
}
export default EducationMeetingPlanningCommittee;
