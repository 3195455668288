import React from 'react';
import NavbarFive from "./NavbarFive";
import Footer from "./Footer";

const Comingsoon = () => {

    return (
        <>
            <NavbarFive />
            {/* coming soon section start */}
            <div className="coming-soon-area">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="coming-soon-content">
                                <h1>Get ready to be empowered!</h1>
                                <p>
                                    A motherlode of resources will be born here soon.
                                </p>
                                <p style={{marginTop: '0px'}}>Stay tuned for something amazing.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* coming soon section end */}
            {/* <Footer /> */}
            <Footer />
        </>
    )
}
export default Comingsoon;