import React, { useState } from "react";
import NavbarFive from "./Common/NavbarFive";
import { useSelector } from "react-redux";
import resourceimg from "../styles/img/resourc.png";
import Footer from "./Common/Footer";
import Preloader from "./Common/Preloader";
import videoimg from "../styles/img/video-icon.png";
// modal section start
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import videotutorial from "../styles/video/5190550-uhd_4096_2160_25fps.mp4";
import { Player } from "video-react";
import { Media, Video, PlayButton } from "@vidstack/player-react";
import classNames from "classnames";
import { isComingSoon } from "../utils/helper";
import ComingSoon from "./Common/ComingSoon";

// import { IoCloseOutline } from "react-icons/io5";
// import { BiLoaderAlt } from "react-icons/bi";

// modal section end

// modal section start
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

// modal section end

const FeistaSession = () => {
  const [loader, setLoader] = React.useState(true);
  const { fiestaData, homeData } = useSelector((state) => state.pageContents);
  React.useEffect(() => {
    setTimeout(() => setLoader(false), 1500);
  }, []);

  // modal section start

  const [videoItem, setVideoItem] = React.useState(null);
  const handleClickOpen = (item) => {
    setVideoItem(item);
  };
  const handleClose = () => {
    setVideoItem(null);
  };

  // modal section end
  const [imgfallvid, setImgfallvid] = React.useState("");
  const [videof, setVideof] = React.useState("");
  const [showSeeAll, setShowSeeAll] = React.useState(false);
  const handleSeeAll = (e) => {
    e.preventDefault();

    setShowSeeAll(!showSeeAll);
  };

  return (
    <>
      {homeData?.comingsoon &&
      isComingSoon(homeData.comingsoon, "Fiesta Sessions") ? (
        <ComingSoon />
      ) : (
        <>
          <NavbarFive />

          <div
            className="page-title-area"
            style={{
              backgroundImage: `url('${fiestaData.fiestabanner.banner_image}')`,
            }}
          >
            <div className="d-table">
              <div className="d-table-cell">
                <div className="container">
                  <div className="page-title-content">
                    <h2> {fiestaData?.fiestabanner?.banner_title}</h2>
                    <ul>
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li className="active">
                        {fiestaData?.fiestabanner?.banner_title}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="heading-video">
                  {fiestaData?.fiestaintro?.section_title}
                </h2>
                {fiestaData?.fiestaintro?.section_description && (
                  <p className="mb-4">
                    {fiestaData?.fiestaintro?.section_description}
                  </p>
                )}
              </div>
            </div>
          </div>
   
          {(fiestaData?.fiestacurrentsession.length ||
            fiestaData?.fiestaoldsession.length) && (
            <div className="doctors-details-area pt-60 feist-sec">
              {fiestaData?.fiestacurrentsession.length && (
                <div className="container feista-sec">
                  <div className="row">
                    <div className=" video-gallery">
                      {fiestaData?.fiestacurrentsession.map((item, index) => (
                        <div className={`col-md-6 gallery-item ${fiestaData.fiestacurrentsession.length == 1 ? "galitm" : ""}`} key={index}>
                          <img src={item.session_image_display} />
                          <div
                            className="gallery-item-caption head-vido"
                            onClick={() => handleClickOpen(item)}
                          >
                            <h2>{item.session_title}</h2>
                            <p>{item.session_date_display}</p>
                            <div className="video-icon">
                              <img src={videoimg} alt="video-icon" />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {fiestaData?.fiestaoldsession.map((item, index) => (
                <React.Fragment key={index}>
                  {(showSeeAll || index == 0) && (
                    <div className="container feista-sec">
                      <div className="row">
                        <div className="archiev-sec video-gallery">
                          <h2>Archive {item.year}</h2>
                          {index === 0 && (
                            <a
                              className="see-sec"
                              href="#"
                              onClick={handleSeeAll}
                            >
                              {showSeeAll ? "See Less" : "See All"}
                            </a>
                          )}
                        </div>
                      </div>

                      <div className="video-gallerys video-gallery">
                        <div className="row">
                          {item.details.map((item2, index2) => (
                            <div className="col-md-3" key={index2}>
                              <div className="gallery-item ">
                                <img
                                  src={item2.session_image_display}
                                  alt={item2.session_title}
                                />
                                <div
                                  className="gallery-item-caption head-vido"
                                  onClick={() => handleClickOpen(item2)}
                                >
                                  <h2>{item2.session_title}</h2>
                                  <p>{item2.session_date_display}</p>
                                  <div className="video-icon video-icons">
                                    <img src={videoimg} alt="video-icon" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          )}

          <Footer />
          {loader ? <Preloader /> : null}
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={videoItem ? true : false}
            className="video-modal-new"
          >
            <DialogTitle
              sx={{ m: 0, p: 2 }}
              id="customized-dialog-title"
            ></DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              {videoItem && (
                <Typography gutterBottom>
                  <Media className="video-sec">
                    <Video
                      autoplay="true"
                      loading="visible"
                      controls
                      preload="true"
                    >
                      <video
                        loading="visible"
                        autoplay="true"
                        src={videoItem.session_video_display}
                        preload="none"
                        data-video="0"
                        controls
                        height="400"
                        width="100%"
                      />
                    </Video>
                  </Media>
                </Typography>
              )}
            </DialogContent>
          </BootstrapDialog>
        </>
      )}
    </>
  );
};
export default FeistaSession;
