import React from "react";
import {
  Link,
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import { Swiper, SwiperSlide } from 'swiper/react';
import { useSelector } from "react-redux";

// Import Swiper styles
import 'swiper/css';
// Import Swiper styles
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import missins from '../../styles/img/our-missionss.jpg';
// import doctors from 'styles/img/home-four/home-four-doctors.jpg';
import doctors from '../../styles/img/home-four-doctors.jpg'

import shape1 from '../../styles/img/home-six-shape1.png';
import shape2 from '../../styles/img/home-six-shape2.png';

const MainBanner = () => {
  const { homeData } = useSelector((state) => state.pageContents);

  return (
    <>
      {
        homeData?.sliderdata.length &&
        <div className="main-banner-six ">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12" style={{ padding: '0px' }}>

                <Carousel
                  showArrows={true}
                  autoPlay={true}
                  showThumbs={false}
                  interval={3000}
                >
                  {
                    homeData.sliderdata.map((item, index) => (
                      <div className="row col-md-12" style={{ margin: '0px' }} key={index}>
                        <div className="col-md-6">
                          <div className="main-banner-content">
                            <div className="d-table">
                              <div className="d-table-cell d-content-caro">
                                <div className="content">
                                  <span className="top-title">{item.lebel}</span>
                                  <h1>{item.title}</h1>
                                  <p>
                                    {item.short_des}
                                  </p>

                                  {/* <Link href="/contact" className="default-btn"> */}
                                  {
                                    item.link &&
                                    <Link href={item.link} className="default-btn">
                                      Book An Appointment
                                    </Link>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6" style={{ paddingRight: '0px' }}>
                          <div
                            className="banner-image banner-new banner-scrolls " style={{ backgroundImage: `url('${item.image}')` }}>
                            {/* {<img src="smfa/static/media/our-mission.629a16e1d20ecbdf2856.jpg" /> } */}
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </Carousel>
              </div>
            </div>
          </div>

          <div className="shape">
            <img src={shape1} alt="Image" />
          </div>
          <div className="shape-2">
            <img src={shape2} alt="Image" />
          </div>
          {/* <p className="illi-sec">Illustration Only</p> */}
        </div>
      }
    </>
  );
};

export default MainBanner;
