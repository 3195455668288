import React from "react";
import Footer from "../components/Common/Footer";
import { useSelector } from "react-redux";
import NavbarFive from "./Common/NavbarFive";
import Preloader from "./Common/Preloader";
import ComingSoon from "./Common/ComingSoon";
import { isComingSoon } from "../utils/helper";

const Guidelines = () => {
  const [loader, setLoader] = React.useState(true);
  const { meetingsData, homeData } = useSelector((state) => state.pageContents);
  React.useEffect(() => {
    setTimeout(() => setLoader(false), 1500);
  }, []);

  return (
    <>
      {homeData?.comingsoon &&
      isComingSoon(homeData.comingsoon, "Guidelines") ? (
        <ComingSoon />
      ) : (
        <>
          <NavbarFive />
          <div
            className="page-title-area"
            style={{
              backgroundImage: `url('${meetingsData.meetingbanner.banner_image}')`,
            }}
          >
            <div className="d-table">
              <div className="d-table-cell">
                <div className="container">
                  <div className="page-title-content">
                    <h2>Guidelines</h2>
                    <ul>
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li className="active">Guidelines</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="doctors-details-area pt-100 guid-margin ">
            <div className="container ">
              <div className="row">
                <div className="col-md-12">
                  <div className="section-title bene-title guidline-sec">
                    {/* <span className="top-title">Woks</span> */}
                    <p>
                      This is the place where you will be able to find
                      Guidelines and Position Statements concerning
                      Maternal-Fetal Anesthesia.
                    </p>
                  </div>
                </div>
              </div>

              <div className="prev-sec-inner">
                <div className="row">
                  <div className="col-md-12">
                    <div className="prev-meet-main guid-main-sec">
                      <div className="prev-meet-sec-right guid-para-sec">
                        <h2>REQUEST A GUIDELINE</h2>
                        <p>
                          If you would like SPANZA to consider the production of
                          a statement, guideline or advice in any specific area,
                          please CLICK HERE to submit an outline of your request
                          detailing the area of interest and the issues being
                          faced, and how such a document would support the work
                          of anaesthetists and improve the care of children.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="prev-sec-inner">
                <div className="row">
                  <div className="col-md-12">
                    <div className="prev-meet-main guid-main-sec">
                      <div className="prev-meet-sec-right guid-para-sec">
                        <h2>
                          SPANZA Advisory on neurotoxicity of general
                          anaesthesia for infants and children
                        </h2>
                        <p>
                          <i>Developed April 2023.</i>{" "}
                        </p>
                        <p>
                          SPANZA are delighted to share with you the SPANZA
                          Advisory on neurotoxicity of general anaesthesia for
                          infants and children.
                        </p>
                        <p>
                          Please <a className="guid-link">CLICK HERE</a> to go
                          to the document.
                        </p>
                        <p>
                          <i>
                            SPANZA would like to acknowledge the work of members
                            in reviewing this document: A/ Prof Paul Lee-Archer,
                            Qld; Prof Andrew Davidson, Vic; Dr Sebastian King,
                            Vic; Dr Jonathan De Lima, NSW; Dr Fiona Macfarlane,
                            Qld
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="prev-sec-inner">
                <div className="row">
                  <div className="col-md-12">
                    <div className="prev-meet-main guid-main-sec">
                      <div className="prev-meet-sec-right guid-para-sec">
                        <h2>SPANZA Sustainability Position Statement</h2>
                        <p>
                          <i>Developed April 2023.</i>{" "}
                        </p>
                        <p>
                          SPANZA would like to thank members for the development
                          of the 2023 SPANZA Sustainability Position Statement.
                        </p>
                        <p>
                          Please <a className="guid-link">CLICK HERE</a> to view
                          the document.
                        </p>
                        <p>
                          <i>
                            This Position Statement has been developed by the
                            SPANZA Sustainability Document Development Group
                            2022. (Dr Lee Beale, SA; Michael Clifford, Vic; Dr
                            Stephanie Cruice, Tas; Dr Paul Currant, NZ; Ben
                            Hallett, Vic; Justin Hii, WA; Su May Koh, Vic;
                            Rebecca McIntyre, Vic; Ian Miles, NSW and David
                            Stoeter, Qld.)
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="prev-sec-inner">
                <div className="row">
                  <div className="col-md-12">
                    <div className="prev-meet-main guid-main-sec">
                      <div className="prev-meet-sec-right guid-para-sec">
                        <h2>PG29</h2>
                        <p>
                          ANZCA’s Professional Document: PG29 – “Guideline for
                          the Provision of Anaesthesia Care to Children” and
                          associated background paper have been published on
                          ANZCA’s website.
                        </p>
                        <p>
                          Development of this updated guideline has been a
                          substantial project and a significant collaboration
                          between ANZCA and SPANZA. The original text of the
                          document was developed by a SPANZA working group, and
                          our processes overseen by the SPANZA Guidelines and
                          Statements subcommittee.
                        </p>
                        <p>
                          <a className="guid-link">CLICK HERE</a> for PG29
                          document and <a className="guid-link">HERE</a> for
                          background paper.
                        </p>
                        <p>
                          SPANZA would like to acknowledge the work of members
                          in reviewing this document:
                        </p>
                        <p>
                          Dr Aimee Clark, NZ; Dr Amanda Dalton, NZ; Dr Patrick
                          Farrell, ANZCA Councillor, NSW; Dr Tanya Farrell,
                          SPANZA Guidelines and Statements Committee, WA; Dr
                          David Linscott, Immediate past president, SPANZA, NZ;
                          Dr Ruth Matters, SPANZA Executive, Tas; Dr Catherine
                          Olweny, Chair SPANZA Guidelines and Statements
                          Committee, Vic
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="prev-sec-inner">
                <div className="row">
                  <div className="col-md-12">
                    <div className="prev-meet-main guid-main-sec">
                      <div className="prev-meet-sec-right guid-para-sec">
                        <h2>
                          ANZCA professional document PG07, Guidelines on
                          Pre-Anaesthesia Consultation and Patient Preparation
                        </h2>
                        <p>
                          ANZCA Safety and Quality committee has revised the
                          Pg07 Document, Guidelines on Pre-Anaesthesia
                          Consultation and Patient Preparation, specifically
                          appendix 1 Fasting Guidelines.
                        </p>
                        <p>
                          SPANZA has contributed to this document to ensure it
                          is consistent with the APAGBI et.al. guideline (see
                          below) on clear fluid fasting for elective paediatric
                          general anaesthesia endorsed by SPANZA.
                        </p>
                        <p>
                          A link to PG07 can be found{" "}
                          <a className="guid-link">HERE</a> and{" "}
                          <a className="guid-link">CLICK HERE</a> for the
                          background paper.
                        </p>
                        <p>
                          SPANZA would like to acknowledge the work of members
                          in reviewing this document:
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </>
      )}
      {loader ? <Preloader /> : null}
    </>
  );
};
export default Guidelines;
