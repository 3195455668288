import React from "react";
import { Link, BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import aboutshape from "../../styles/img/home/home-six-about-shape.png";
import membershipimg from "../../styles/img/member-ship.jpg";
import { useSelector } from "react-redux";

const OurMission = () => {
  const { homeData } = useSelector((state) => state.pageContents);

  return (
    <>
      {homeData?.homemission && (
        <div className="ours-doctors-area">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6">
                <div className="doctors-content ptb-100">
                  <span className="top-title">
                    {homeData.homemission.mission_lebel}
                  </span>
                  <h2>{homeData.homemission.mission_title}</h2>
                  <p>{homeData.homemission.short_description}</p>

                  <ul>
                    {homeData.homemission.mission_list_first != null &&
                      homeData.homemission.mission_list_first != "" && (
                        <li>
                          <i className="flaticon-tick"></i>
                          {homeData.homemission.mission_list_first}
                        </li>
                      )}
                    {homeData.homemission.mission_list_second != null &&
                      homeData.homemission.mission_list_second != "" && (
                        <li>
                          <i className="flaticon-tick"></i>
                          {homeData.homemission.mission_list_second}
                        </li>
                      )}

                    {homeData.homemission.mission_list_third != null &&
                      homeData.homemission.mission_list_third != "" && (
                        <li>
                          <i className="flaticon-tick"></i>
                          {homeData.homemission.mission_list_third}
                        </li>
                      )}

                  </ul>

                  {/* <Link href="/about" className="default-btn"> */}
                  <Link to="/onlineapplication" className="default-btn">
                    Join Now
                  </Link>
                </div>
              </div>

              <div className="col-lg-6 pr-0">
                {/* <div className="doctors-img about" style={{ backgroundImage:  `url(${membershipimg})` }}></div> */}
                <div
                  className="doctors-img "
                  style={{ backgroundImage: `url(${membershipimg})` }}
                ></div>
              </div>
            </div>
            {/* <div className="col-md-12">
            <p className="illi-sec">Illustration Only</p>
            </div> */}
          </div>

          {/* <div className="shape">
            <img src={aboutshape} />
          </div> */}
        </div>
      )}
    </>
  );
};

export default OurMission;
